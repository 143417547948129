import base64 from 'base-64';
import jwt from 'jsonwebtoken';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    setCookie: function(cname: any, cvalue: any, exdays: any)
    {
        const dados = this.decodeToken(cvalue);
        const exp = dados.exp;
        const d = new Date();
        //d.setTime(d.getTime() + (exdays*24*60*60*1000));
        d.setTime(d.getTime() + exp)
        const expires = "expires="+ d.getTime().toString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    decodeToken: function(cvalue: any)
    {
        cvalue = base64.decode(cvalue);
        try
        {
            const secret:any = process.env.REACT_APP_SECRET;
            try
            {
                var decoded :any = jwt.verify(cvalue, secret);
               return decoded
            }catch(error)
            {
                console.log(error)
            }
        }catch(err)
        {

        }
    },
    getCookie: function(cname: any)
    {
        const name = cname + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++)
        {
            var c = ca[i]
            while (c.charAt(0) === ' ') 
            {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) 
            {
                return c.substring(name.length, c.length);
            }
        }
        return null;
    },
    getUserLogado: function()
    {
        const token = this.getCookie('token');
        const token_decode = base64.decode((token != null) ? token : '');
        try 
        {
            const secret:any = process.env.REACT_APP_SECRET;
            try
            {
                var decoded = jwt.verify(token_decode, secret);
               return decoded
            }catch(error)
            {
                const d = new Date();
                const year = d.getFullYear() - 1;
                d.setFullYear(year);
                const expires = "expires="+ d.toUTCString();
                document.cookie = 'token' + "=" + '' + ";" + expires + ";path=/";
            }
        }catch(err) {
            return err;
        }
        
    },
    logout: function()
    {
        const d = new Date();
        const year = d.getFullYear() - 1;
        d.setFullYear(year);
        const expires = "expires="+ d.toUTCString();
        document.cookie = 'token' + "=" + '' + ";" + expires + ";path=/";
    },
    getTipoAcesso:function()
    {
        //const user_encoded = this.getUserLogado();

        const user = this.getUserLogado()

        return Number(user.tipo)

    },
    getName:function()
    {
        const user = this.getUserLogado()
        return user.nome

    }
}