import React, {useEffect, useState, useContext} from 'react';
import { Alert, Button, Container, Form } from 'react-bootstrap';
import { useHistory } from 'react-router';
import MainContent from '../../../components/AppComponents/MainContent';
import SideBar from '../../../components/AppComponents/SideBar';
import { SideBarContext } from '../../../providers/SideBarStatus';
import auth from '../../../services/auth';
import $ from 'jquery';
import 'jquery-mask-plugin';
import api from '../../../services/api';

function Audienciasadd()
{

    const {selected, setSelected}: any = useContext(SideBarContext);

    const history = useHistory();

    const [numero_processo, setNumeroProcesso] = useState("");
    const [data_inicial, setDataInicial] = useState("");
    const [data_final, setDataFinal] = useState("");
    const [data_hora, setDataHora] = useState("");
    const [prazo, setPrazo] = useState("");
    const [erro, setErro] = useState(false);
    const [erroMsg, setErroMsg] = useState("");

    useEffect(()=>{
        loadMask();
    },[]);

    const loadMask = ()=>
    {
        $("#dataInicial").mask("00/00/0000");
        $("#dataFinal").mask("00/00/0000");
    }

    const checkboxChange = (e: any)=>
    {
        if(selected.selected === false)
        {
            setSelected({
                selected: true
            })
        }else
        {
            setSelected({
                selected: false
            })
        }
    }


    const navigation = (route: any)=>
    {
        history.push(route);
    }


    const logout = ()=>
    {
        auth.logout();
    }

    const handleSubmit = (e:React.FormEvent)=>
    {
        e.preventDefault();
        

        const data = JSON.stringify({
            "numero_processo_audiencia": numero_processo,
            "data_iniciar": data_inicial,
            "data_final": data_final,
            "data_hora_audiencia": data_hora,
            "contagem_prazo": prazo,
            "status":"Marcada"
        });

        const config = {
            headers: {
                "x-access-token": auth.getCookie("token"),
                "Content-Type":"application/json"
            }
        }

        api.post("/v1/audiencias", data, config).then(function(response)
        {
           if(response.data.msg === "Audiência Cadastrado com sucesso.")
           {
                setErro(false)
                setErroMsg("");
                navigation("/audiencias");
           }
        }).catch(function(error)
        {
            setErro(true);
            setErroMsg("Ocorreu um erro tente novamente.");
        });


    }

    


    const handleChange = (e: any)=>{
        const {name, value} = e.target;

        switch(name)
        {
            case "numero_processo":
                setNumeroProcesso(value)
                break;
            case "data_inicial":
                setDataInicial(value)
                break;
            case "data_final":
                setDataFinal(value)
                break;
            case "data_hora":
                setDataHora(value)
                break;     
            case "prazo":
                setPrazo(value)
                break;          

        }

    }

    return (
        <div className="fundo">
            <input type="checkbox" checked={selected.selected} onChange={checkboxChange} id="sidebar-toogle"></input>
            <SideBar>
                <li>
                    <a href="#" onClick={logout}>
                        <span className="fas fa-sign-out-alt"></span>
                        <span>Sair</span>
                    </a>
                </li>
            </SideBar>
            <MainContent title="Audiências Adicionar" tipoAcesso={auth.getTipoAcesso()} permissionOne={true} permissionTwo={true} permissionThree={true}>
                <Container>
                    <Button onClick={()=>navigation("/audiencias")}>Voltar</Button><br /><br />
                    {(erro) ?
                        <Alert variant="danger">
                            {erroMsg}
                        </Alert>
                    : null}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Label>Número do Processo: </Form.Label>
                            <Form.Control required type="text" onChange={handleChange} name="numero_processo" placeholder="Número do Processo"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Prazo Inicial: </Form.Label>
                            <Form.Control required type="text" onChange={handleChange} name="data_inicial" id="dataInicial" placeholder="Data Inicial"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Prazo final: </Form.Label>
                            <Form.Control required type="text" onChange={handleChange} name="data_final" id="dataFinal" placeholder="Data Final"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Audiência Data e Hora: </Form.Label>
                            <Form.Control required type="text" onChange={handleChange} name="data_hora" placeholder="Data e Hora"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Prazo: </Form.Label>
                            <Form.Control required type="number" onChange={handleChange} min="0" name="prazo" placeholder="Prazo"/>
                        </Form.Group>
                        <Button variant="primary" type="submit">Salvar</Button>
                    </Form>
                </Container>    
            </MainContent>
        </div>
    )
}

export default Audienciasadd;