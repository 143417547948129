import React, {useEffect, useState, useContext } from 'react'
import SideBar from '../../components/AppComponents/SideBar';
import auth from '../../services/auth';
import { useHistory } from 'react-router';
import {SideBarContext} from '../../providers/SideBarStatus';
import MainContent from '../../components/AppComponents/MainContent';
import { Button, Container, Form, Table } from 'react-bootstrap';
import api from '../../services/api';
import { EditorContext } from '../../providers/Editor';
import EditorJS from '../../components/EditorJS/index';
import $ from 'jquery';

function Processo()
{
    const {selected, setSelected}: any = useContext(SideBarContext);
    const {valor, setValor}:any = useContext(EditorContext);
    const [statusModal, setStatusModal] = useState(false)

    const [listProcessos, setProcessos] = useState([])
    const [processoAtual, setProcessoAtual] = useState(Object)
    const [tituloEmail, setTituloEmail] = useState("")

    const history = useHistory();


    useEffect(()=>{
        const token = auth.getCookie('token');
        listAllProcessos(token);
        setValor("");
    }, [])

    const checkboxChange = (e: any)=>
    {
        if(selected.selected === false)
        {
            setSelected({
                selected: true
            })
        }else
        {
            setSelected({
                selected: false
            })
        }
    }

    const navigation = (route: any)=>
    {
        history.push(route);
    }

    const logout = ()=>
    {
        auth.logout();
    }


    const listAllProcessos = async (token: any)=>
    {
        const response = await api.get('/v1/processos/', {
            headers: { 
                'x-access-token': token, 
                'Content-Type': 'application/json'
            },
        });

        const data = response.data;

        setProcessos(data);
    }


    const deleteProcesso = async (id_processo: any)=>
    {
        const response = await api.delete('/v1/processos/'+id_processo+'', {
            headers: { 
                'x-access-token': auth.getCookie('token'), 
                'Content-Type': 'application/json'
            },
        })

        if(response.data.msg == "Processo deletado com sucesso.")
        {
            window.location.reload();
        }
    }


    const mascaraCPFCNPJ = (cpfcnpj:any)=>
    {
        if(cpfcnpj != "")
        {
            if(cpfcnpj.length <= 11)
            {
                cpfcnpj=cpfcnpj.replace(/\D/g,"");
                cpfcnpj=cpfcnpj.replace(/(\d{3})(\d)/,"$1.$2");
                cpfcnpj=cpfcnpj.replace(/(\d{3})(\d)/,"$1.$2");
                cpfcnpj=cpfcnpj.replace(/(\d{3})(\d{1,2})$/,"$1-$2")
            }else
            {
                cpfcnpj=cpfcnpj.replace(/\D/g,"");
                cpfcnpj = cpfcnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5");
            }
            return cpfcnpj;
        }else
        {
            return "";
        }
    }


    const processoAtivoView = (processo: any, index:any)=>{
        if(processo.ativo === 0)
        {

            let preco = processo['valor'];

            let preco_formatado = preco.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});

            return (
                <tr key={index}>
                    <th>{processo['id_processo']}</th>
                    <td>{processo['numero_processo']}</td>
                    <td>{processo['advogado']}</td>
                    <td>{processo['instancia']}</td>
                    <td>{processo["nome"]}</td>
                    <td>{mascaraCPFCNPJ(processo['cpf_exequente'])}</td>
                    <td>{processo['executado']}</td>
                    <td>{preco_formatado}</td>
                    <td><Button variant="success" onClick={()=>showForm(processo)}>Enviar</Button> / <Button variant="primary" onClick={()=>navigation('/editProcesso/'+processo["id_processo"]+'')}>Editar</Button> / <Button variant="danger" onClick={()=>deleteProcesso(processo["id_processo"])}>Deletar</Button></td>
                </tr>
            )
        }
    }

    const processoBaixadoView = (processo: any, index:any)=>{


        let preco = processo['valor'];

        let preco_formatado = preco.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
        

        if(processo.ativo === 1)
        {
            return (
                <tr key={index}>
                    <th>{processo['id_processo']}</th>
                    <td>{processo['numero_processo']}</td>
                    <td>{processo['advogado']}</td>
                    <td>{processo['instancia']}</td>
                    <td>{processo["nome"]}</td>
                    <td>{mascaraCPFCNPJ(processo['cpf_exequente'])}</td>
                    <td>{processo['executado']}</td>
                    <td>{preco_formatado}</td>
                    <td><Button variant="primary" onClick={()=>navigation('/editProcesso/'+processo["id_processo"]+'')}>Editar</Button> / <Button variant="danger" onClick={()=>deleteProcesso(processo["id_processo"])}>Deletar</Button></td>
                </tr>
            )
        }
    }

    const escoderModal = ()=>{
        setStatusModal(false)
    }


    const emailSubmit = (e: React.FormEvent)=>{
        e.preventDefault();

        var settings = {
            "url": "https://apidata.pelucioadvocacia.adv.br/removeHtml.php",
            "method": "POST",
            "timeout": 0,
            "headers": {
              "Content-Type": "application/x-www-form-urlencoded"
            },
            "data": {
              "html": valor
            }
          };
          
          $.ajax(settings).done(function (response) {
            enviarEmail(tituloEmail, response.noHtml, valor, processoAtual.email)
            setStatusModal(false)
          });

    }


    

    const onChangeEmail = (e: any)=>{
        setTituloEmail(e.target.value)
    }

    const exibirModal = ()=>{
        if(statusModal)
        {
            return (
                <div>
                    <div className='mymodal'>
                        <div>
                            <button title="Fechar" className='fechar' onClick={()=>escoderModal()}>x</button>
                            <h2>{processoAtual.numero_processo}</h2><br></br>
                            <Form onSubmit={emailSubmit}>
                                <Form.Group>
                                    <Form.Label>Título: </Form.Label>
                                    <Form.Control required type="text" value={tituloEmail} onChange={onChangeEmail} name="titulo" placeholder="Título"/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Texto: </Form.Label>
                                    <EditorJS />
                                </Form.Group>
                                <Button variant="primary" type="submit">Enviar</Button>
                            </Form>
                            
                        </div>
                    </div>
                </div>
            )
        }else
        {
            return null
        }
    }

    const showForm = (processo:any)=>{
        setStatusModal(true)
        setProcessoAtual(processo)
    }


    const enviarEmail = async (title:any, msg:any, msg_html:any, email:any)=>
    {
        const data = JSON.stringify({
            "email":email,
            "title":title,
            "msg": msg,
            "html":msg_html
        })

        const config = {
           headers: {
                "x-access-token": auth.getCookie('token'),
                "Content-Type":"application/json"
           }
        }

        api.post("/v1/mail/", data, config).then(response=>{
            console.log(response)
        }).catch(error=>{
            console.log(error)
        })
    }

    return (
        <div className="fundo">
            <input type="checkbox" checked={selected.selected} onChange={checkboxChange} id="sidebar-toogle"></input>
            <SideBar>
                <li>
                    <a href="#" onClick={logout}>
                        <span className="fas fa-sign-out-alt"></span>
                        <span>Sair</span>
                    </a>
                </li>
            </SideBar>
            <MainContent title="Processos" tipoAcesso={auth.getTipoAcesso()} permissionOne={true} permissionTwo={false} permissionThree={false}>
                <Container>
                    <Button onClick={()=>navigation("/addProcesso")}>Adicionar</Button><br /><br />
                    <h2 style={{textAlign: 'center'}}>Ativo</h2><br></br>
                    <Table striped bordered variant="dark">
                        <thead>
                            <tr>
                                <th>id</th>
                                <td>N°</td>
                                <td>Advogado</td>
                                <td>Instância</td>
                                <td>Exequente Nome</td>
                                <td>Exequente CPF</td>
                                <td>Executado</td>
                                <td>Valor</td>
                                <td>Ação</td>
                            </tr>
                        </thead>
                        <tbody>
                            {listProcessos.map((processo, index)=>(
                                processoAtivoView(processo, index)
                            ))}
                        </tbody>
                    </Table><br></br>
                    <h2 style={{textAlign: 'center'}}>Baixados</h2><br></br>
                    <Table striped bordered variant="dark">
                        <thead>
                            <tr>
                                <th>id</th>
                                <td>N°</td>
                                <td>Advogado</td>
                                <td>Instância</td>
                                <td>Exequente Nome</td>
                                <td>Exequente CPF</td>
                                <td>Executado</td>
                                <td>Valor</td>
                                <td>Ação</td>
                            </tr>
                        </thead>
                        <tbody>
                            {listProcessos.map((processo, index)=>(
                                processoBaixadoView(processo, index)
                            ))}
                        </tbody>
                    </Table>
                </Container>
            </MainContent>
            {exibirModal()}
        </div>
    )
}


export default Processo;