import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import './styles/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Routes from './components/Routes/Routes';
import {HashRouter} from 'react-router-dom';
import { SideBarProvider } from './providers/SideBarStatus';
import { EditorProvider } from './providers/Editor';


ReactDOM.render(
  <React.StrictMode>
      <SideBarProvider>
          <EditorProvider>
            <HashRouter>
              <Routes />
            </HashRouter>
          </EditorProvider>
      </SideBarProvider>
  </React.StrictMode>,
  document.getElementById('root')
);