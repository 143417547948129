import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../components/FontAwesomeIcons'
import './styles.css';
import { Link, useHistory  } from 'react-router-dom';
import api from '../../services/api';
import auth from '../../services/auth';
import {Alert} from 'react-bootstrap';

function Login()
{
    const [user, setUser] = useState();
    const [password, setPassword] = useState();
    const [error, setError] = useState(false);
    const [errormsg, setErrorMsg] = useState("");

    const history = useHistory();

    useEffect(()=>{
        const token = !!auth.getCookie('token');

        if(token)
        {
            history.push('/');
        }

    }, []);


    

    const setUserChange = (e: any)=>
    {
        setUser(e.target.value);
    }


    const setPasswordChange = (e: any)=>
    {
        setPassword(e.target.value)
    }

    const submit = async (e: any)=>
    {
        e.preventDefault();

        if(user != null && password != null)
        {

            var data = JSON.stringify({
              "user": user,
              "senha": password
            });


            var config: any = {
                method: 'post',
                headers: { 
                  'x-access-token': auth.getCookie('token'), 
                  'Content-Type': 'application/json'
                },
                data : data
            };
            
            /*await axios({
                method: 'post',
                url: 'https://pelucio.api.pelucioadvocacia.adv.br/v1/users/login',
                headers: { 
                    'Content-Type': 'application/json'
                },
                data : data
            }).then(function (response)
            {
                const data = response.data

                if(data.error == false)
                {
                    setError(false);
                    setErrorMsg("");
                    auth.setCookie('token', data.token, 1);

                    history.push('/');
                }

            }).catch(function (error) 
            {
                setError(true);
                setErrorMsg("Usuário Inválido.");
            });*/

           api.post('/v1/users/login', data, config).then(function(response)
           {
                const data = response.data

                if(data.error == false)
                {
                    setError(false);
                    setErrorMsg("");
                    auth.setCookie('token', data.token, 1);

                    history.push('/');
                }

           }).catch(function(error)
           {
                setError(true);
                setErrorMsg("Usuário Inválido.");
           })
        }else
        {
            const data_err = true;
           setError(data_err);
           setErrorMsg("Usuário ou senha Inválidos.");
        }
    }



    return (
        <div className="fundo">
            <form className='form' onSubmit={submit}>
                <h1><FontAwesomeIcon icon="user" /> Login</h1>
                {error == true ? <Alert variant="danger">{errormsg}</Alert> : ''}
                <input type="text" onChange={setUserChange} placeholder="Digite o Nome de Usuário" />
                <input type="password" onChange={setPasswordChange} placeholder="Digite sua senha" />
                <input type="submit" value="Entrar" />
            </form>
            
        </div>
    )
}

export default Login;