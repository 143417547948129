import React from 'react';
function PrivateLink(props: any)
{

    const {tipoAcesso, permissionOne, permissionTwo, permissionThree, children} = props;

    if(tipoAcesso === 1 && permissionOne === true || tipoAcesso === 2 && permissionTwo === true || tipoAcesso === 3 && permissionThree === true)
    {
        return (
            <li>
                {children}
            </li>
        )
    }else
    {
        return (
            null
        )
    }
}

export default PrivateLink;