import React, {useEffect, useState, useContext } from 'react'
import { Button, Container, Table } from 'react-bootstrap';
import { useHistory } from 'react-router';
import MainContent from '../../components/AppComponents/MainContent';
import SideBar from '../../components/AppComponents/SideBar';
import {SideBarContext} from '../../providers/SideBarStatus';
import api from '../../services/api';
import auth from '../../services/auth';


function Audiencias()
{
    const {selected, setSelected}: any = useContext(SideBarContext);

    const [audiencias, setAudiencias] = useState([]);

    const history = useHistory();


    useEffect(()=>{
        getAudiencias()
    },[]);

    const getAudiencias = async ()=>
    {
        const response = await api.get("v1/audiencias/listAll/processosaudienciasclientes", {
            headers: {
                "x-access-token": auth.getCookie("token")
            }
        });

        setAudiencias(response.data);
    }


    const checkboxChange = (e: any)=>
    {
        if(selected.selected === false)
        {
            setSelected({
                selected: true
            })
        }else
        {
            setSelected({
                selected: false
            })
        }
    }


    const deleteAudiencia = (id_audiencia: any)=>
    {

        var config = {
            headers: {
                "x-access-token": auth.getCookie("token")
            }
        }

        api.delete("/v1/audiencias/"+id_audiencia+"", config).then(function(response)
        {
            window.location.reload();
        }).catch(function(error)
        {

        })
    }


    const navigation = (route: any)=>
    {
        history.push(route);
    }


    const logout = ()=>
    {
        auth.logout();
    }

    const enviarCliente = (nome:any, data_hora:any, numero_whatsapp:any, email: any,status:any)=>
    {
        // eslint-disable-next-line no-restricted-globals
        var confirmar = confirm("Tem certeza que deseja enviar para o cliente ?");


        if(confirmar === true)
        {

            if(status === "Marcada")
            {
                let msg_html = '<p>Olá Sr(a) '+nome+' tudo bem ?</p><p>O motivo do meu contato para informa-lo(a) que sua audiência foi marcada para o dia '+data_hora+'</p>';
                let msg_whatsapp = 'Ol%C3%A1%20Sr(a)%20'+nome+'%20tudo%20bem%20%3F%0AO%20motivo%20do%20meu%20contato%20para%20informa-lo(a)%20que%20sua%20audiência%20foi%20marcada%20para%20o%20dia%20'+data_hora+'';
                let msg = 'Olá Sr(a) '+nome+' tudo bem ? O motivo do meu contato para informa-lo(a) que sua audiência foi marcada para o dia '+data_hora+'';

                let link_whatsapp = 'https://api.whatsapp.com/send?phone=55'+numero_whatsapp+'&text='+msg_whatsapp+''

                window.open(link_whatsapp)
                enviarEmail("Audiência", msg, msg_html, email);

            }else if(status === "Alterada")
            {
                let msg_html = '<p>Olá Sr(a) '+nome+' tudo bem ?</p><p>O motivo do meu contato para informa-lo(a) que sua audiência foi alterada para o dia '+data_hora+'</p>';
                let msg_whatsapp = 'Ol%C3%A1%20Sr(a)%20'+nome+'%20tudo%20bem%20%3F%0AO%20motivo%20do%20meu%20contato%20para%20informa-lo(a)%20que%20sua%20audiência%20foi%20alterada%20para%20o%20dia%20'+data_hora+'';
                let msg = 'Olá Sr(a) '+nome+' tudo bem ? O motivo do meu contato para informa-lo(a) que sua audiência foi alterada para o dia '+data_hora+'';

                let link_whatsapp = 'https://api.whatsapp.com/send?phone=55'+numero_whatsapp+'&text='+msg_whatsapp+''

                window.open(link_whatsapp)
                enviarEmail("Audiência", msg, msg_html, email);
            }else if(status === "Cancelada")
            {
                let msg_html = '<p>Olá Sr(a) '+nome+' tudo bem ?</p><p>O motivo do meu contato para informa-lo(a) que sua audiência foi cancelada.</p>';
                let msg_whatsapp = 'Ol%C3%A1%20Sr(a)%20'+nome+'%20tudo%20bem%20%3F%0AO%20motivo%20do%20meu%20contato%20para%20informa-lo(a)%20que%20sua%20audiência%20foi%20cancelada.';
                let msg = 'Olá Sr(a) '+nome+' tudo bem ? O motivo do meu contato para informa-lo(a) que sua audiência foi cancelada.';

                let link_whatsapp = 'https://api.whatsapp.com/send?phone=55'+numero_whatsapp+'&text='+msg_whatsapp+''

                window.open(link_whatsapp)
                enviarEmail("Audiência", msg, msg_html, email);

            }

        }

    }

    const enviarEmail = async (title:any, msg:any, msg_html:any, email:any)=>
    {
        const data = JSON.stringify({
            "email":email,
            "title":title,
            "msg": msg,
            "html":msg_html
        })

        const config = {
           headers: {
                "x-access-token": auth.getCookie('token'),
                "Content-Type":"application/json"
           }
        }

        api.post("/v1/mail/", data, config).then(response=>{
            console.log(response)
        }).catch(error=>{
            console.log(error)
        })
    }

    const audienciasMarcadas = (audiencia: any, index: any)=>
    {
        if(audiencia.status === "Marcada")
        {
            return (
                <tr key={index}>
                    <th>{audiencia['id_audiencia']}</th>
                    <th>{audiencia['numero_processo_audiencia']}</th>
                    <th>{audiencia['nome']}</th>
                    <th>{audiencia['contagem_prazo']} Dias</th>
                    <th>{audiencia['data_iniciar']}</th>
                    <th>{audiencia['data_final']}</th>
                    <th>{audiencia['data_hora_audiencia']}</th>
                    <td><Button variant="success" onClick={()=>enviarCliente(audiencia['nome'], audiencia['data_hora_audiencia'], audiencia['whatsapp'], audiencia['email'], audiencia['status'])}>Enviar</Button> / <Button variant="primary" onClick={()=>navigation('/editAudiencia/'+audiencia["id_audiencia"]+'')}>Editar</Button> / <Button variant="danger" onClick={()=>deleteAudiencia(audiencia["id_audiencia"])}>Deletar</Button></td>
                </tr>
            )
        }
    }


    const audeienciasHoje = (audiencia: any, index: any)=>
    {
        if(hoje(audiencia["data_hora_audiencia"]) && audiencia.status != "Cancelada")
        {
            return (
                <tr key={index}>
                    <th>{audiencia['id_audiencia']}</th>
                    <th>{audiencia['numero_processo_audiencia']}</th>
                    <th>{audiencia['nome']}</th>
                    <th>{audiencia['contagem_prazo']} Dias</th>
                    <th>{audiencia['data_iniciar']}</th>
                    <th>{audiencia['data_final']}</th>
                    <th>{audiencia['data_hora_audiencia']}</th>
                    <td><Button variant="success" onClick={()=>enviarCliente(audiencia['nome'], audiencia['data_hora_audiencia'], audiencia['whatsapp'], audiencia['email'], audiencia['status'])}>Enviar</Button> / <Button variant="primary" onClick={()=>navigation('/editAudiencia/'+audiencia["id_audiencia"]+'')}>Editar</Button> / <Button variant="danger" onClick={()=>deleteAudiencia(audiencia["id_audiencia"])}>Deletar</Button></td>
                </tr>
            )
        }
    }


    const hoje = (audiencia_data_hora:any)=>
    {
        const data = new Date();

        const dia:any = (data.getDate() < 10) ? '0'+data.getDate() : data.getDate()
        const mes:any = (data.getMonth() + 1 < 10) ? '0'+(data.getMonth()+ 1)   : data.getMonth() + 1
        const ano:any = data.getFullYear()

        const fullDate = dia + "/" + mes + "/" + ano;

        const dataAudienciaHora = audiencia_data_hora.split(" - ")

        return (fullDate == dataAudienciaHora[0]) ? true : false

    }

    const audienciasAlteradas = (audiencia: any, index: any)=>
    {
        if(audiencia.status === "Alterada")
        {
            return (
                <tr key={index}>
                    <th>{audiencia['id_audiencia']}</th>
                    <th>{audiencia['numero_processo_audiencia']}</th>
                    <th>{audiencia['nome']}</th>
                    <th>{audiencia['contagem_prazo']} Dias</th>
                    <th>{audiencia['data_iniciar']}</th>
                    <th>{audiencia['data_final']}</th>
                    <th>{audiencia['data_hora_audiencia']}</th>
                    <td><Button variant="success" onClick={()=>enviarCliente(audiencia['nome'], audiencia['data_hora_audiencia'], audiencia['whatsapp'], audiencia['email'], audiencia['status'])}>Enviar</Button> / <Button variant="primary" onClick={()=>navigation('/editAudiencia/'+audiencia["id_audiencia"]+'')}>Editar</Button> / <Button variant="danger" onClick={()=>deleteAudiencia(audiencia["id_audiencia"])}>Deletar</Button></td>
                </tr>
            )
        }
    }

    const audienciasCanceladas = (audiencia: any, index: any)=>
    {
        if(audiencia.status === "Cancelada")
        {
            return (
                <tr key={index}>
                    <th>{audiencia['id_audiencia']}</th>
                    <th>{audiencia['numero_processo_audiencia']}</th>
                    <th>{audiencia['nome']}</th>
                    <th>{audiencia['contagem_prazo']} Dias</th>
                    <th>{audiencia['data_iniciar']}</th>
                    <th>{audiencia['data_final']}</th>
                    <th>{audiencia['data_hora_audiencia']}</th>
                    <td><Button variant="success" onClick={()=>enviarCliente(audiencia['nome'], audiencia['data_hora_audiencia'], audiencia['whatsapp'], audiencia['email'], audiencia['status'])}>Enviar</Button> / <Button variant="primary" onClick={()=>navigation('/editAudiencia/'+audiencia["id_audiencia"]+'')}>Editar</Button> / <Button variant="danger" onClick={()=>deleteAudiencia(audiencia["id_audiencia"])}>Deletar</Button></td>
                </tr>
            )
        }
    }

    return (
        <div className="fundo">
            <input type="checkbox" checked={selected.selected} onChange={checkboxChange} id="sidebar-toogle"></input>
            <SideBar>
                <li>
                    <a href="#" onClick={logout}>
                        <span className="fas fa-sign-out-alt"></span>
                        <span>Sair</span>
                    </a>
                </li>
            </SideBar>
            <MainContent title="Audiências" tipoAcesso={auth.getTipoAcesso()} permissionOne={true} permissionTwo={true} permissionThree={true}>
                <Container>
                    <Button onClick={()=>navigation("/addAudiencias")}>Adicionar</Button><br /><br />
                    <h2 style={{textAlign: 'center'}}>Audiências de Hoje</h2><br></br>
                    <Table striped bordered variant="dark">
                        <thead>
                            <tr>
                                <th>id</th>
                                <td>N°</td>
                                <td>Exequente</td>
                                <td>Prazo</td>
                                <td>Prazo Início</td>
                                <td>Prazo Final</td>
                                <td>Audiência</td>
                                <td>Ação</td>
                            </tr>
                        </thead>
                        <tbody>
                            {audiencias.map((audiencia, index)=>(
                                audeienciasHoje(audiencia, index)
                            ))}
                        </tbody>
                    </Table>
                    <h2 style={{textAlign: 'center'}}>Prazo / Audiência Marcadas</h2><br></br>
                    <Table striped bordered variant="dark">
                        <thead>
                            <tr>
                                <th>id</th>
                                <td>N°</td>
                                <td>Exequente</td>
                                <td>Prazo</td>
                                <td>Prazo Início</td>
                                <td>Prazo Final</td>
                                <td>Audiência</td>
                                <td>Ação</td>
                            </tr>
                        </thead>
                        <tbody>
                            {audiencias.map((audiencia, index)=>(
                                audienciasMarcadas(audiencia, index)
                            ))}
                        </tbody>
                    </Table>
                    <h2 style={{textAlign: 'center'}}>Prazo / Audiência Alteradas</h2><br></br>
                    <Table striped bordered variant="dark">
                        <thead>
                            <tr>
                                <th>id</th>
                                <td>N°</td>
                                <td>Exequente</td>
                                <td>Prazo</td>
                                <td>Prazo Início</td>
                                <td>Prazo Final</td>
                                <td>Audiência</td>
                                <td>Ação</td>
                            </tr>
                        </thead>
                        <tbody>
                            {audiencias.map((audiencia, index)=>(
                                audienciasAlteradas(audiencia, index)
                            ))}
                        </tbody>
                    </Table>
                    <h2 style={{textAlign: 'center'}}>Prazo / Audiência Canceladas</h2><br></br>
                    <Table striped bordered variant="dark">
                        <thead>
                            <tr>
                                <th>id</th>
                                <td>N°</td>
                                <td>Exequente</td>
                                <td>Prazo Início</td>
                                <td>Prazo Final</td>
                                <td>Audiência</td>
                                <td>Prazo</td>
                                <td>Ação</td>
                            </tr>
                        </thead>
                        <tbody>
                            {audiencias.map((audiencia, index)=>(
                                audienciasCanceladas(audiencia, index)
                            ))}
                        </tbody>
                    </Table>
                </Container>
            </MainContent>
        </div>
    )
}

export default Audiencias;