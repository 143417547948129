import axios from "axios";

async function getDate()
{
    try
    {
        const response = await axios.get("https://apidata.pelucioadvocacia.adv.br/");

        return response.data;
    }catch(error)
    {
        return null;
    }
    
}


export default getDate;