import React, {useState} from 'react';


export const SideBarContext = React.createContext({})

export const SideBarProvider = (props: any)=>
{

    const [selected, setSelected] = useState({
        selected: false
    })

    return (
        <SideBarContext.Provider value={{selected, setSelected}}>
            {props.children}
        </SideBarContext.Provider>
    )
}