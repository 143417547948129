import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import auth from '../../services/auth';

const PrivateRoute = (props: any) => {
    
    const isLogeed = !!auth.getCookie('token');
    return isLogeed ? <Route {...props}/> : <Redirect to="/login"/>

}

export default PrivateRoute;