import React, {useEffect, useState, useContext} from 'react';
import { Alert, Button, Container, Form } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import MainContent from '../../../components/AppComponents/MainContent';
import SideBar from '../../../components/AppComponents/SideBar';
import { SideBarContext } from '../../../providers/SideBarStatus';
import auth from '../../../services/auth';
import $ from 'jquery';
import 'jquery-mask-plugin';
import api from '../../../services/api';

function Audienciasaedit()
{

    const {selected, setSelected}: any = useContext(SideBarContext);

    const history = useHistory();

    const [numero_processo, setNumeroProcesso] = useState("");
    const [data_inicial, setDataInicial] = useState("");
    const [data_final, setDataFinal] = useState("");
    const [data_hora, setDataHora] = useState("");
    const [prazo, setPrazo] = useState("");
    const [status, setStatus] = useState("");
    const [erro, setErro] = useState(false);
    const [erroMsg, setErroMsg] = useState("");

    interface RouteParams {
        id_audiencias: string
    }

    const {id_audiencias} = useParams<RouteParams>();


    useEffect(()=>{
        loadMask();
        loadAudiencia()
    },[]);

    const loadAudiencia = async ()=>
    {

        const config = {
            headers: {
                "x-access-token": auth.getCookie("token")
            }
        }

        try
        {
            const response = await api.get("/v1/audiencias/"+id_audiencias+"", config)
    
            const {data} = response;
    
            setNumeroProcesso(data.numero_processo_audiencia);
            setDataInicial(data.data_iniciar);
            setDataFinal(data.data_final);
            setDataHora(data.data_hora_audiencia);
            setPrazo(data.contagem_prazo)
            setStatus(data.status)
        }catch(error)
        {
            setErro(true);
            setErroMsg("Ocorreu um erro tente novamente.")
        }

    }

    const loadMask = ()=>
    {
        $("#dataInicial").mask("00/00/0000");
        $("#dataFinal").mask("00/00/0000");
    }

    const checkboxChange = (e: any)=>
    {
        if(selected.selected === false)
        {
            setSelected({
                selected: true
            })
        }else
        {
            setSelected({
                selected: false
            })
        }
    }


    const navigation = (route: any)=>
    {
        history.push(route);
    }


    const logout = ()=>
    {
        auth.logout();
    }

    const handleSubmit = (e:React.FormEvent)=>
    {
        e.preventDefault();

        const data = JSON.stringify({
            "numero_processo_audiencia": numero_processo,
            "data_iniciar": data_inicial,
            "data_final": data_final,
            "data_hora_audiencia": data_hora,
            "contagem_prazo": prazo,
            "status":status
        });

        const config = {
            headers: {
                "x-access-token": auth.getCookie("token"),
                "Content-Type":"application/json"
            }
        }


        api.put("/v1/audiencias/"+id_audiencias+"", data, config).then(function(response)
        {
            setErro(false);
            setErroMsg("")
            navigation("/audiencias");
        }).catch(function(error)
        {
            setErro(true);
            setErroMsg("Ocorreu um erro tente novamente.")
        })

    }

    
    const handleChange = (e: any)=>{
        const {name, value} = e.target;

        switch(name)
        {
            case "numero_processo":
                setNumeroProcesso(value)
                break;
            case "data_inicial":
                setDataInicial(value)
                break;
            case "data_final":
                setDataFinal(value)
                break;
            case "data_hora":
                setDataHora(value)
                break;     
            case "prazo":
                setPrazo(value)
                break;
            case "status":
                setStatus(value)
                break;      

        }

    }

    return (
        <div className="fundo">
            <input type="checkbox" checked={selected.selected} onChange={checkboxChange} id="sidebar-toogle"></input>
            <SideBar>
                <li>
                    <a href="#" onClick={logout}>
                        <span className="fas fa-sign-out-alt"></span>
                        <span>Sair</span>
                    </a>
                </li>
            </SideBar>
            <MainContent title="Audiências Editar" tipoAcesso={auth.getTipoAcesso()} permissionOne={true} permissionTwo={true} permissionThree={true}>
                <Container>
                    <Button onClick={()=>navigation("/audiencias")}>Voltar</Button><br /><br />
                    {(erro) ?
                        <Alert variant="danger">
                            {erroMsg}
                        </Alert>
                    : null}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Label>Número do Processo: </Form.Label>
                            <Form.Control required type="text" onChange={handleChange} name="numero_processo" value={numero_processo} placeholder="Número do Processo"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Data Inicial: </Form.Label>
                            <Form.Control required type="text" onChange={handleChange} name="data_inicial" value={data_inicial} id="dataInicial" placeholder="Data Inicial"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Data final: </Form.Label>
                            <Form.Control required type="text" onChange={handleChange} name="data_final" value={data_final} id="dataFinal" placeholder="Data Final"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Data e Hota: </Form.Label>
                            <Form.Control required type="text" onChange={handleChange} name="data_hora" value={data_hora} placeholder="Data e Hota"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Prazo: </Form.Label>
                            <Form.Control required type="number" onChange={handleChange} value={prazo} name="prazo" placeholder="Prazo"/>
                        </Form.Group>
                        <Form.Group>
                                <Form.Label>Status:</Form.Label>
                                <Form.Control required name="status" value={status} defaultValue={status} onChange={handleChange} as="select">
                                    <option disabled value="">Selecione uma Opção</option>
                                    <option value="Marcada">Marcada</option>
                                    <option value="Alterada">Alterada</option>
                                    <option value="Cancelada">Cancelada</option>
                                </Form.Control>
                        </Form.Group>
                        <Button variant="primary" type="submit">Salvar</Button>
                    </Form>
                </Container>    
            </MainContent>
        </div>
    )
}

export default Audienciasaedit;