import React, {useEffect, useState, useContext} from 'react';
import { Button, Container, FormControl, Table } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import MainContent from '../../components/AppComponents/MainContent';
import SideBar from '../../components/AppComponents/SideBar';
import { SideBarContext } from '../../providers/SideBarStatus';
import api from '../../services/api';

import auth from '../../services/auth';

function ListaTelefonica()
{

    const {selected, setSelected}: any = useContext(SideBarContext);

    const history = useHistory();

    const [telefones, setTelefones] = useState([])
    const [search, setSearch] = useState("");

    useEffect(()=>{
        getTelefones()
    },[])


    const getTelefones = async ()=>
    {

        const config = {
            headers: {
                "x-access-token": auth.getCookie("token")
            }
        }

        const response = await api.get("/v1/listaTelefone", config)

        const data = response.data;

        setTelefones(data)
    }

    const checkboxChange = (e: any)=>
    {
        if(selected.selected === false)
        {
            setSelected({
                selected: true
            })
        }else
        {
            setSelected({
                selected: false
            })
        }
    }

    const navigation = (route: any)=>
    {
        history.push(route);
    }

    const logout = ()=>
    {
        auth.logout();
    }

    const deletar = (id:any)=>
    {
        // eslint-disable-next-line no-restricted-globals
        var confirmar = confirm("Tem certeza que deseja deletar este item ?")

        if(confirmar === true)
        {

            const config = {
                headers:{
                    "x-access-token": auth.getCookie("token")
                }
            }

            api.delete("/v1/listaTelefone/"+id+"", config).then(response=>{
                if(response.data.msg === "Telefone deletado com sucesso")
                {
                    window.location.reload()
                }
            }).catch(error=>{
                
            })
        }

    }

    const handleChange = (e:React.FormEvent)=>
    {
        var {value}:any = e.target

        if(value != "")
        {
            setSearch(value);
            getSearch();
        }else
        {
            getTelefones();
        }

    }

    const getSearch = ()=>
    {

        const data = JSON.stringify({
            "search": search
        });

        const config = {
            headers:{
                "Content-Type":"Application/json",
                "x-access-token":auth.getCookie("token")
            }
        }

        api.post("/v1/listaTelefone/search", data, config).then(response=>{
            setTelefones(response.data)
        }).catch(error=>{
            
        })
    }

    return (
        <div className="fundo">
            <input type="checkbox" checked={selected.selected} onChange={checkboxChange} id="sidebar-toogle"></input>
            <SideBar>
                <li>
                    <a href="#" onClick={logout}>
                        <span className="fas fa-sign-out-alt"></span>
                        <span>Sair</span>
                    </a>
                </li>
            </SideBar>
            <MainContent title="Lista Telefônica" tipoAcesso={auth.getTipoAcesso()} permissionOne={true} permissionTwo={true} permissionThree={true}>
                <Container>
                    <Button onClick={()=>navigation("/listatelefoneAdd")}>Adicionar Telefone</Button><br /><br />
                    <div className="text-center">
                        <form>
                            <FormControl name="search" onChange={handleChange} placeholder="Buscar"/>
                        </form>
                    </div><br/>
                    <Table striped bordered variant="dark">
                        <thead>
                            <tr>
                                <th>id</th>
                                <td>Tipo Lista</td>
                                <td>Nome</td>
                                <td>Número</td>
                                <td>Ação</td>
                            </tr>
                        </thead>
                        <tbody>
                            {telefones.map((telefone, index)=>(
                                <tr key={index}>
                                    <td>{telefone['id']}</td>
                                    <td>{telefone['tipo_lista']}</td>
                                    <td>{telefone['nome']}</td>
                                    <td>{telefone['numero']}</td>
                                    <td><Button variant="primary" onClick={()=>navigation("/listatelefoneEdit/"+telefone['id']+"")}>Editar</Button> / <Button variant="danger" onClick={()=>deletar(telefone["id"])}>Deletar</Button></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Container>
            </MainContent>
        </div>
    )
}

export default ListaTelefonica;