import React from  'react';
import { Redirect, useHistory } from 'react-router';
import './styles.css'


function MainContent(props: any)
{

    const history = useHistory();

    const {title, tipoAcesso, permissionOne, permissionTwo, permissionThree, children} = props;

    if(tipoAcesso === 1 && permissionOne === true || tipoAcesso === 2 && permissionTwo === true || tipoAcesso === 3 && permissionThree === true)
    {
        return (
            <div className="main-content">
                <header>
                    <h2>{title}</h2>
                </header>
                <main>
                    {children}
                </main>
            </div>
        );
    }else
    {
        return (
            <Redirect to="/" />
        )
    }
}

export default MainContent;