import Axios from 'axios';
import getApiUrl from './GetApiUrl';

const api = Axios.create({
    //baseURL: "http://localhost:5000",
    baseURL: getApiUrl(),
    headers: {
        'Content-Type': 'application/json'
    }
})


export default api;