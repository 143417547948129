import {Router, Switch, Route} from 'react-router-dom';
import Login from '../../pages/Login';
import React from 'react';
import {history} from './history';
import NotFound from '../NotFound';
import PrivateRoute from './privateRoutes';
import AddUser from '../../pages/Usuarios/AddUser';
import EditUser from '../../pages/Usuarios/EditUser';
import Usuarios from '../../pages/Usuarios';
import Home from '../../pages/Home';
import Clientes from '../../pages/Clientes';
import AddCliente from '../../pages/Clientes/AddCliente';
import EditCliente from '../../pages/Clientes/EditClient';
import Processo from '../../pages/Processo';
import addProcesso from '../../pages/Processo/AddProcesso';
import EditProcesso from '../../pages/Processo/EditProcesso';
import Audiencias from '../../pages/Audiencias';
import Audienciasadd from '../../pages/Audiencias/Audienciasadd';
import Audienciasaedit from '../../pages/Audiencias/Audienciasedit';
import ListaTelefonica from '../../pages/ListaTelefonica';
import AddTelefone from '../../pages/ListaTelefonica/AddTelefone';
import EditTelefone from '../../pages/ListaTelefonica/EditTelefone';
import ValorDoContrato from '../../pages/ValorDoContrato';
import AddValorDoContrato from '../../pages/ValorDoContrato/AddValorDoContrato';
import EditValorDoContrato from '../../pages/ValorDoContrato/EditValorDoContrato';

const Routes = ()=>(
    <Router history={history}>
        <Switch>
            <Route path='/login' component={Login} exact/>
            <PrivateRoute path='/' component={Home} exact />
            <PrivateRoute path='/usuarios' component={Usuarios} exact />
            <PrivateRoute path='/adduser' component={AddUser} />
            <PrivateRoute path='/edituser/:id' component={EditUser}/>
            <PrivateRoute path="/clientes" component={Clientes} />
            <PrivateRoute path="/addcliente" component={AddCliente} />
            <PrivateRoute path="/editCliente/:id" component={EditCliente} />
            <PrivateRoute path="/processo" component={Processo} />
            <PrivateRoute path="/addprocesso" component={addProcesso} />
            <PrivateRoute path="/editProcesso/:id_processo" component={EditProcesso} />
            <PrivateRoute path="/audiencias" component={Audiencias} />
            <PrivateRoute path="/addAudiencias" component={Audienciasadd} />
            <PrivateRoute path="/editAudiencia/:id_audiencias" component={Audienciasaedit} />
            <PrivateRoute path="/valor-do-contrato" component={ValorDoContrato} />
            <PrivateRoute path="/valor-do-contratoAdd" component={AddValorDoContrato} />
            <PrivateRoute path="/valor-do-contratoEdit/:id_valor_do_contrato" component={EditValorDoContrato} />
            <PrivateRoute path="/listatelefone" component={ListaTelefonica} />
            <PrivateRoute path="/listatelefoneAdd" component={AddTelefone} />
            <PrivateRoute path="/listatelefoneEdit/:id_lista_telefone" component={EditTelefone} />
            <Route component={NotFound} />
        </Switch>
    </Router>
);

export default Routes;