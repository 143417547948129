import React, {useState, useContext, useEffect, useCallback} from 'react'
import SideBar from '../../../components/AppComponents/SideBar';
import auth from '../../../services/auth';
import {useHistory, useParams} from 'react-router-dom';
import {SideBarContext} from '../../../providers/SideBarStatus';
import MainContent from '../../../components/AppComponents/MainContent';
import { Button, Container, Form, Table } from 'react-bootstrap';
import api from '../../../services/api';
import $ from 'jquery';
import ValorDaCausa from '..';

function EditValorDoContrato()
{

    const {selected, setSelected} = useContext(SideBarContext);
    const history = useHistory();
    const [numero, setNumero] = useState("")
    const [valorContrato, setValorContrato] = useState("")
    const [parcelas, setParcelas] = useState([])
    const [avistaValidation, setAvistaValidation] = useState(false)
    const [avistaParcelado, setAvistaParcelado] = useState("")



    const {id_valor_do_contrato} = useParams();
    

    useEffect(()=>{
        const token = auth.getCookie("token")
        getValorContrato(token)
    },[])


    const getValorContrato = async(token)=>
    {
        const config = {
            headers:{
                'x-access-token': token, 
                'Content-Type': 'application/json'
            }
        }
        const response = await api.get("/v1/valordocontrato/getValorContrato/"+id_valor_do_contrato+"", config)

        const data = response.data
        console.log(data)
        setNumero(data.numero_processo)
        $("#numero_processo").val(data.numero_processo)
        setParcelas(data.parcelas)
        var valor_contrato = response.data.valor_total
        var valor_contrato_formatado = valor_contrato.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}).split("R$").join("")
        setValorContrato(valor_contrato_formatado)
        setAvistaParcelado(data.avista_parcelado)
        if(data.avista_parcelado !== "À Vista")
        {
            setAvistaValidation(true)
        }
    }

    const checkboxChange = (e)=>
    {
        if(selected.selected === false)
        {
            setSelected({
                selected: true
            })
        }else
        {
            setSelected({
                selected: false
            })
        }
    }

    const navigation = (route)=>
    {
        history.push(route)
    }

    const handleChange = (e)=>
    {
        const target = e.target

        // eslint-disable-next-line default-case
        switch(target.name)
        {
            case "numero":
                setNumero(target.value)
            break;
            case "avista_parcelado":
                if(target.value === "Parcelado")
                {
                    setAvistaValidation(true)
                }else
                {
                    setAvistaValidation(false)
                    setParcelas(arr => [...arr.splice(0, arr.length)])
                }
                setAvistaParcelado(target.value)
            break
        }
    }

    

    const onBlur = useCallback((e)=>{
        var numero_processo = e.target.value
        loadProcessoDados(numero_processo)
    },[])


    const loadProcessoDados = async(numero_processo)=>
    {
        try
        {
            const response = await api.get("/v1/processos/getProcessoNumero/"+numero_processo+"", {
                headers: {
                    "x-access-token": auth.getCookie('token'),
                    "Content-Type":"application/json"
               }
            })
            var valor_contrato = response.data.valor_contrato

            var valor_contrato_formatado = valor_contrato.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}).split("R$").join("")

            setValorContrato(valor_contrato_formatado)
        }catch(error)
        {
            setValorContrato("")
        }
        
    }


    const addParcela = ()=>
    {
        var valor_parcela = $("#valor_parcela").val()
        var valor_formatado = valor_parcela.split(".").join("")

        var parcela_obj = {
            id: Math.random(),
            valor_parcela:parseFloat(valor_formatado.split(",").join(".")),
            data_vencimento:$("#data_vencimento").val()
        }

        setParcelas(arr => [...arr, parcela_obj])
    }

    const handleSubmit = async(e)=>
    {
        e.preventDefault()

        const valorContratoFormatado = valorContrato.split(".").join("")

        const data = JSON.stringify({
            "numero_processo":numero,
            "valor_total":parseFloat(valorContratoFormatado.split(",").join(".")),
            "avista_parcelado":avistaParcelado,
            "parcelas":parcelas
        })

        var config = {
            method: 'put',
            headers: { 
              'x-access-token': auth.getCookie('token'), 
              'Content-Type': 'application/json'
            },
            data : data
        };


        api.put("/v1/valordocontrato/update/"+id_valor_do_contrato+"", data, config).then(response=>
        {
            if(response.data.msg === "Valor do Contrato Atualizado com sucesso.")
            {
                history.push("/valor-do-contrato")
            }
        }).catch(error=>
        {

        })

    }

    const logout = ()=>
    {
        auth.logout();
    }

    const deleteParcela = (id)=>
    {
        const novasParcelas = parcelas.filter((parcela) => parcela.id !== id);
        setParcelas(novasParcelas);
    }

    return (
        <div className='fundo'>
            <input type="checkbox" checked={selected.selected} onChange={checkboxChange} id="sidebar-toogle"></input>
            <SideBar>
                <li>
                    <a href="#" onClick={logout}>
                        <span className="fas fa-sign-out-alt"></span>
                        <span>Sair</span>
                    </a>
                </li>
            </SideBar>
            <MainContent title="Editar Valor do Contrato" tipoAcesso={auth.getTipoAcesso()} permissionOne={true} permissionTwo={false} permissionThree={false}>
                <Container>
                    <Button onClick={()=>navigation("/valor-do-contrato")}>Voltar</Button><br></br><br></br>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Label>Número do Processo: </Form.Label>
                            <Form.Control required type="text" id="numero_processo" onBlur={onBlur} onChange={handleChange} name="numero" placeholder="Número do Processo"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Valor Total: </Form.Label>
                            <Form.Control required type="text" id='valor_total' value={valorContrato} readOnly name="valor_total" placeholder="Valor Total"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>À Vista/Parcelado: </Form.Label>
                            <Form.Control required name="avista_parcelado" value={avistaParcelado} defaultValue={avistaParcelado} onChange={handleChange} as="select">
                                    <option disabled value="">Selecione uma Opção</option>
                                    <option value="À Vista">À Vista</option>
                                    <option value="Parcelado">Parcelado</option>
                                </Form.Control>
                        </Form.Group>
                        {avistaValidation ? 
                            <div>
                                <h2>Parcelas</h2>
                                <Form.Group>
                                    <Form.Label>Valor da parcela: </Form.Label>
                                    <Form.Control type="text" id='valor_parcela' name="valor_parcela" placeholder="Valor da Parcela"/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>Data de Vencimento: </Form.Label>
                                    <Form.Control type="text" id='data_vencimento' name="data_vencimento" placeholder="Data de Vencimento"/>
                                </Form.Group>
                                <Button type='button' onClick={()=>addParcela()}>Adicionar Parcela</Button><br></br><br></br>
                                
                                <Table variant='dark'>
                                    <thead>
                                        <tr>
                                            <th>Valor da parcela</th>
                                            <th>Data de Vencimento</th>
                                            <th>Ação</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {parcelas.map((parcela, index)=>(
                                            <tr key={index}>
                                                <td>{formatMoeda(parcela["valor_parcela"])}</td>
                                                <td>{parcela["data_vencimento"]}</td>
                                                <td><Button onClick={()=>deleteParcela(parcela["id"])} variant='danger'>Excluir</Button></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                            
                        : null}
                        
                        <Button type='submit'>Salvar</Button>
                    </Form>
                </Container>
            </MainContent>
        </div>
    )
}

function formatMoeda(valor)
{
    const valorFormatdo = valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})

    return valorFormatdo
}

export default EditValorDoContrato