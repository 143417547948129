import React, {useEffect, useState, useContext} from 'react';
import { Alert, Button, Container, Form } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import MainContent from '../../../components/AppComponents/MainContent';
import SideBar from '../../../components/AppComponents/SideBar';
import { SideBarContext } from '../../../providers/SideBarStatus';
import api from '../../../services/api';
import auth from '../../../services/auth';

function EditTelefone()
{

    const {selected, setSelected}: any = useContext(SideBarContext);
    const history = useHistory();

    const [tipo_lista, setTipoLista] = useState("");
    const [nome, setNome] = useState("")
    const [numero, setNumero] = useState("")
    const [erro, setErro] = useState(false)
    const [erroMsg, setErroMsg] = useState("")


    interface RouteParams {
        id_lista_telefone: string
    }

    const {id_lista_telefone} = useParams<RouteParams>();


    useEffect(()=>{
        getTelefone()
    },[])


    const getTelefone = async ()=>
    {
        const config = {
            headers: {
                "x-access-token":auth.getCookie("token")
            }
        }


        const response = await api.get("/v1/listaTelefone/"+id_lista_telefone+"", config)

        const data = response.data;
    
        setTipoLista(data.tipo_lista)
        setNome(data.nome)
        setNumero(data.numero)

    }

    const checkboxChange = (e: any)=>
    {
        if(selected.selected === false)
        {
            setSelected({
                selected: true
            })
        }else
        {
            setSelected({
                selected: false
            })
        }
    }

    const navigation = (route: any)=>
    {
        history.push(route);
    }

    const logout = ()=>
    {
        auth.logout();
    }

    const handleChange = (e: any)=>
    {
        const {name, value} = e.target;

        switch(name)
        {
            case "tipo_lista":
                setTipoLista(value)
                break;
            case "nome":
                setNome(value)
                break;
            case "numero":
                setNumero(value)
                break;   
        }
    }

    const handleSubmit = async (e:React.FormEvent)=>
    {
        e.preventDefault();

        var data = JSON.stringify({
            "tipo_lista": tipo_lista,
            "nome":nome,
            "numero":numero
        });

        const config = {
            headers: {
                "x-access-token":auth.getCookie("token"),
                "Content-Type":"application/json"
            }
        }

        api.put("/v1/listaTelefone/"+id_lista_telefone+"", data, config).then(response=>{
            if(response.data.msg === "Telefone atualizado com sucesso")
            {
                setErro(false)
                setErroMsg("")
                navigation("/listatelefone")
            }
        }).catch(error=>{
            setErro(true)
            setErroMsg("Ocorreu um erro ao cadastrar.")
        })

    }


    return (
        <div className="fundo">
            <input type="checkbox" checked={selected.selected} onChange={checkboxChange} id="sidebar-toogle"></input>
            <SideBar>
                <li>
                    <a href="#" onClick={logout}>
                        <span className="fas fa-sign-out-alt"></span>
                        <span>Sair</span>
                    </a>
                </li>
            </SideBar>
            <MainContent title="Add Telefone" tipoAcesso={auth.getTipoAcesso()} permissionOne={true} permissionTwo={true} permissionThree={true}>
                <Container>
                    {erro == true ? <Alert variant="danger">{erroMsg}</Alert> : ''}
                    <form onSubmit={handleSubmit}> 
                        <Form.Group>
                            <Form.Label>Categoria:</Form.Label>
                            <Form.Control onChange={handleChange} value={tipo_lista} name="tipo_lista" placeholder="Categoria"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Nome:</Form.Label>
                            <Form.Control onChange={handleChange} value={nome} name="nome" placeholder="nome"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Telefone</Form.Label>
                            <Form.Control onChange={handleChange} value={numero} name="numero" placeholder="Telefone"/>
                        </Form.Group>
                        <Button type="submit" variant="primary">Editar</Button>
                    </form>
                </Container>
            </MainContent>
        </div>
    )
}

export default EditTelefone;