import React, { useState, useEffect, useContext } from 'react';
import { Button, Container, Table } from 'react-bootstrap';
import { useHistory } from 'react-router';
import MainContent from '../../components/AppComponents/MainContent';
import SideBar from '../../components/AppComponents/SideBar';
import auth from '../../services/auth';
import api from '../../services/api';
import {SideBarContext} from '../../providers/SideBarStatus';
import getDate from '../../services/getDate';
import getApiUrl from '../../services/GetApiUrl';

const str2ab = require('string-to-arraybuffer')

function Clientes()
{

    const [clientes, setClientes] = useState([])

    const {selected, setSelected}: any = useContext(SideBarContext);

    const [dataAtual, setDataAtual] = useState({
        dia: "00",
        mes:"00",
        ano:"0000"
    })

    const history = useHistory();


    useEffect(()=>{
        const token = auth.getCookie('token');

        const dataAtualObj = getDate();

        dataAtualObj.then(data=>{
            setDataAtual(data)
        }).catch(error=>{
            setDataAtual({
                dia: "00",
                mes:"00",
                ano:"0000"
            })
        })
        listAllClientes(token)
    }, []);


    const listAllClientes = async(token:any)=>
    {
        const response = await api.get('/v1/clientes/?token='+token+'');
        setClientes(response.data)
    }

    const navigation = (route: any)=>
    {
        history.push(route);
    }

    const logout = ()=>
    {
        auth.logout();
    }

    const checkboxChange = (e: any)=>
    {
        if(selected.selected === false)
        {
            setSelected({
                selected: true
            })
        }else
        {
            setSelected({
                selected: false
            })
        }
    }

    const getCPFCNPJ = (cpfcnpj:any)=>
    {
        cpfcnpj = cpfcnpj.replace(/\D/g, "")
        if(cpfcnpj.length <= 11)
        {
            cpfcnpj = cpfcnpj.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
        }else
        {
            cpfcnpj = cpfcnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5");
        }
        return cpfcnpj;
    }

    const deletar = async (id:any, token:any)=>
    {
        const response = await api.delete('/v1/clientes/'+id+'/?token='+token+'');
        window.location.reload()
    
    }


    const aniversariantes = (cliente:any, index:any)=>
    {
        const diaAtual = dataAtual.dia;
        const mesAtual = dataAtual.mes;
        const anoAtual = parseInt(dataAtual.ano);

        var dataNascimentoCliente = cliente.data_nascimento;

        dataNascimentoCliente = dataNascimentoCliente.split("/");

        const diaMesNascimentoCliente = dataNascimentoCliente[0] + "/" + dataNascimentoCliente[1];
        const dataAtualDiaMes = diaAtual + "/" + mesAtual;

        const idadeCliente = anoAtual - parseInt(dataNascimentoCliente[2]);

        if(diaMesNascimentoCliente == dataAtualDiaMes)
        {
            return (
                <tr key={index}>
                    <td>{cliente.id}</td>
                    <td>{cliente.nome}</td>
                    <td>{idadeCliente}</td>
                    <td><Button variant="success" onClick={()=>enviarMsg(cliente.email, cliente.whatsapp, cliente.nome)}>Enviar</Button></td>
                </tr>
            )
        }
    }


    const enviarMsg = async (email:any, whatsapp: any, nome:any)=>
    {
        // eslint-disable-next-line no-restricted-globals
        var confirmar = confirm("Tem certeza que deseja enviar para o cliente ?");

        if(confirmar)
        {
            let msg_html = "<p>Olá "+nome+"<p><p>Sempre nos sentimos muitos felizes por ter alguém tão especial como cliente. Receba os nossos mais sinceros votos de felicidades. Parabéns pelo seu aniversário!</p><p><strong>Pelucio Advocacia</strong></p>";
            let msg = "Olá "+nome+" Sempre nos sentimos muitos felizes por ter alguém tão especial como cliente. Receba os nossos mais sinceros votos de felicidades. Parabéns pelo seu aniversário!Pelucio Advocacia";
            let whatsapp_msg = "https://api.whatsapp.com/send?phone=55"+whatsapp+"&text=Ol%C3%A1%20"+nome+"%0ASempre%20nos%20sentimos%20muitos%20felizes%20por%20ter%20algu%C3%A9m%20t%C3%A3o%20especial%20como%20cliente.%20Receba%20os%20nossos%20mais%20sinceros%20votos%20de%20felicidades.%20Parab%C3%A9ns%20pelo%20seu%20anivers%C3%A1rio!%0APelucio%20Advocacia";

            window.open(whatsapp_msg)
            enviarEmailAniversario(email, msg, msg_html)
        }
    }

    const enviarEmailAniversario = async(email: any, msg: any, msg_html:any)=>
    {
        const data = JSON.stringify({
            "email":email,
            "title":"Feliz Aniversário",
            "msg": msg,
            "html":msg_html
        })

        const config = {
            headers: {
                 "x-access-token": auth.getCookie('token'),
                 "Content-Type":"application/json"
            }
         }
 
         api.post("/v1/mail/", data, config).then(response=>{
             console.log(response)
         }).catch(error=>{
             console.log(error)
         })
    }

    const downloadAdministrativosDoc =(nome_cliente:any, id_cliente:any)=>
    {
        const cliente_nome = nome_cliente.split(" ").join("_")
        return "/docs/"+id_cliente+"-"+cliente_nome+"_administrativos.doc?token="+auth.getCookie('token')+"";
    }

    return (
        <div className="fundo">
            <input type="checkbox" checked={selected.selected} onChange={checkboxChange} id="sidebar-toogle"></input>
            <SideBar>
                <li>
                    <a href="#" onClick={logout}>
                        <span className="fas fa-sign-out-alt"></span>
                        <span>Sair</span>
                    </a>
                </li>
            </SideBar>
            <MainContent title="Clientes" tipoAcesso={auth.getTipoAcesso()} permissionOne={true} permissionTwo={true} permissionThree={false}>
                <Container>
                    <Button onClick={()=>navigation("/addcliente")}>Adicionar</Button><br /><br />
                    <h2>Clientes</h2>
                    <Table striped bordered variant="dark">
                        <thead>
                            <tr>
                                <th>id</th>
                                <th>Nome</th>
                                <th>CPF/CNPJ</th>
                                <th>RG</th>
                                <td>Ação</td>
                            </tr>
                        </thead>
                        <tbody>
                            {clientes.map((cliente, index)=>(
                                <tr key={index}>
                                    <td>{cliente['id']}</td>
                                    <td>{cliente['nome']}</td>
                                    <td>{getCPFCNPJ(cliente['cpf'])}</td>
                                    <td>{cliente['rg']}</td>
                                    <td><a href={getApiUrl()+downloadAdministrativosDoc(cliente['nome'], cliente['id'])} className='btn btn-info'>Adminstarativos</a> / <Button variant="primary" onClick={()=>navigation('/editCliente/'+cliente["id"]+'')}>Editar</Button> / <Button variant="danger" onClick={()=>deletar(cliente['id'], auth.getCookie('token'))}>Delete</Button></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table><br/><br/>
                    <h2>Aniversariantes</h2>
                    <Table striped bordered variant="dark">
                        <thead>
                            <tr>
                                <th>id</th>
                                <th>Nome</th>
                                <th>idade</th>
                                <td>Enviar Mensagem</td>
                            </tr>
                        </thead>
                        <tbody>
                            {clientes.map((cliente, index)=>(
                               aniversariantes(cliente, index)
                            ))}
                        </tbody>
                    </Table>
                </Container>
            </MainContent>
        </div>
    )
}


export default Clientes;