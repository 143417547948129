import React, {useEffect, useState, useContext} from 'react';
import { Container, Form, Col, Button, Alert } from 'react-bootstrap';
import {SideBarContext} from '../../../providers/SideBarStatus';
import SideBar from '../../../components/AppComponents/SideBar';
import { useHistory, useParams } from 'react-router';
import api from '../../../services/api';
import auth from '../../../services/auth';
import cep from '../../../services/cep';
import './styles.css';
import MainContent from '../../../components/AppComponents/MainContent';
import $ from 'jquery';
import 'jquery-mask-plugin'
import socket from 'socket.io-client';
import getApiUrl from '../../../services/GetApiUrl';

const io = socket(getApiUrl(), {transports:["websocket"]})


function EditCliente()
{
    const {selected, setSelected}: any = useContext(SideBarContext);

    const [nome, setNome] = useState("");
    const [cpf, setCpf] = useState("");
    const [rg, setRg] = useState("");
    const [estadoCivil, setEstadoCivil] = useState();
    const [profissao, setProfissao] = useState();
    const [senha, setSenha] = useState("");
    const [estado, setEstado] = useState("");
    const [cidade, setCidade] = useState("");
    const [bairro, setBairro] = useState("");
    const [rua, setRua] = useState("");
    const [numero, setNumero] = useState("");
    const [erro, setErro] = useState(false);
    const [erroMsg, setErroMsg] = useState("");
    const [dataNascimento, setDataNascimento] = useState("");
    const [telefone, setTelefone] = useState("");
    const [whatsapp, setWhatsapp] = useState("");
    const [email, setEmail] = useState("");
    const [cepInp, setCepInp] = useState("");
    const [nacionalidade, setNacionalidade] = useState("");
    const [pai, setPai] = useState("");
    const [mae, setMae] = useState("");

    interface RouteParams {
        id: string
    }

    const {id} = useParams<RouteParams>();

    const history = useHistory()

    useEffect(()=>{
        setMask()
        getUser(auth.getCookie('token'))
        io?.on('connect', () => console.log('[IO] Connect => A new connection has been established'))
    }, []);

    const setMask = ()=>{
        $("#cep").mask("00000-000")
        //$("#cpf").mask("000.000.000-00")
        $("#dataNascimento").mask("00/00/0000");
    }


    const getUser = async (token:any)=>
    {
        const response = await api.get('/v1/clientes/'+id+'/?token='+token+'')
        const {data} = response;
        setNome(data.nome)
        var cpf = cpfCnpjMask(data.cpf);
        $("#cpfcnpj").val(cpf);
        setCpf(cpf)
        setRg(data.rg)
        setEstadoCivil(data.estado_civil)
        setProfissao(data.profissao);
        var cep = data.cep;
        cep = cep.replace(/\D/g, "");
        cep = cep.replace(/^(\d{5})(\d)/, "$1-$2");
        $("#cep").val(cep)
        setCepInp(cep)
        setEstado(data.estado)
        setCidade(data.cidade)
        setBairro(data.bairro)
        setRua(data.rua);
        setNumero(data.numero)
        setDataNascimento(data.data_nascimento)
        setTelefone(data.telefone)
        setWhatsapp(data.whatsapp)
        setEmail(data.email)
        setNacionalidade(data.nacionalidade)
        setPai(data.pai)
        setMae(data.mae)
    }
    
    const checkboxChange = (e: any)=>
    {
        if(selected.selected === false)
        {
            setSelected({
                selected: true
            })
        }else
        {
            setSelected({
                selected: false
            })
        }
    }


    const handleCepKeyUp = async(e: React.FormEvent<HTMLInputElement>)=>
    {
        let value = e.currentTarget.value;
        console.log(e.currentTarget.value)
        value = value.replace(/\D/g, "");
        if(value.length == 8)
        {
            setCepInp(value)
            const response  = await cep.get('/ws/'+value+'/json/');
            console.log(response.data)
            setEstado(response.data['uf']);
            setCidade(response.data["localidade"])
        }else
        {
            setEstado("");
            setCidade("");
        }
    }

    const handleKeyUpCPF = (e: React.FormEvent<HTMLInputElement>)=>
    {
        let value = e.currentTarget.value;

        value = value.replace(/\D/g, "")
        if(value.length === 11)
        {
            validarCpf(value);
            
        }else if(value.length >= 14)
        {
            validarCNPJ(value);
        }
    }

    const handleChangeEstado = (e: React.FormEvent<HTMLInputElement>)=>
    {
        setEstado(e.currentTarget.value)
    }

    const handleChangeCidade = (e: React.FormEvent<HTMLInputElement>)=>
    {
        setCidade(e.currentTarget.value)
    }

    const navigation = (route: any)=>
    {
        history.push(route);
    }

    const formatarCampo = (e: React.FormEvent<HTMLInputElement>)=>
    {
        let value = e.currentTarget.value

        if(value.length <= 11)
        {
            e.currentTarget.value = mascaraCpf(value)
        }else
        {
            e.currentTarget.value = mascaraCnpj(value)
        }

    }

    const retirarFormatacao = (e: React.FormEvent<HTMLInputElement>)=>
    {
        e.currentTarget.value = e.currentTarget.value.replace(/(\.|\/|\-)/g,"");
    }

    const mascaraCpf = (valor:any)=>
    {
        return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"\$1.\$2.\$3\-\$4");
    }

    const mascaraCnpj = (valor:any)=>
    {
        return valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5");
    }

    const validarCNPJ = (cnpj:any)=>
    {
        let inputCNPJ = cnpj;

        console.log(cnpj)


        io?.emit("data-cpf-cnpj", inputCNPJ);

        io?.on("data-cpf-cnpj-isValid", function(data:any)
        {
            if(data)
            {
                setErro(false);
                setErroMsg("");
                setCpf(cnpj)
            }else
            {
                setErro(true);
                setErroMsg("CNPJ Inválido.");
                setCpf("")
            }
        })
    }

    const validarCpf = (cpf:any)=>
    {
        let inputCPF = cpf;


        io?.emit("data-cpf-cnpj", inputCPF);

        io?.on("data-cpf-cnpj-isValid", function(data:any)
        {
            if(data)
            {
                setErro(false);
                setErroMsg("");
                setCpf(cpf)
            }else
            {
                setErro(true);
                setErroMsg("CPF Inválido.");
                setCpf("aaa")
            }
        })
    }


    const handleChange = (e: any)=>
    {
        const {name, value} = e.target;

        if(name == "nome")
        {
            //console.log("nome: " + value)
            setNome(value)
        }else if(name == "cpf")
        {
            setCpf(value);
        }else if(name == "rg")
        {
            console.log(value)
            setRg(value);
        }else if(name == "estado_civil")
        {
            setEstadoCivil(value)
        }else if(name == "Profisao")
        {
            setProfissao(value)
        }else if(name == "Senha")
        {
            setSenha(value)
        }else if(name == "cep")
        {
            setCepInp(value)
        }else if(name == "bairro")
        {
            setBairro(value)
        }else if(name == "rua")
        {
            setRua(value)
        }else if(name == "numero")
        {
            setNumero(value)
        }else if(name == "data_nascimento")
        {
            setDataNascimento(value);
        }else if(name == "telefone")
        {
            setTelefone(value)
        }else if(name == "whatsapp")
        {
            setWhatsapp(value)
        }else if(name == "email")
        {
            setEmail(value)
        }else if(name == "nacionalidade")
        {
            setNacionalidade(value)
        }else if(name == "nome_pai")
        {
            setPai(value)
        }else if(name == "nome_mae")
        {
            setMae(value)
        }
    }


    const handleSubmit = (e: React.FormEvent)=>
    {
        e.preventDefault();
        editCliente(auth.getCookie('token'))
    }


    const editCliente = async (token: any)=>
    {

        var cpfFormata = cpf.split(".").join("")
        

        var data = JSON.stringify({
            "nome": nome,
            "cpf": cpfFormata.split("-").join(""),
            "rg": rg,
            "estado_civil": estadoCivil,
            "nacionalidade":nacionalidade,
            "profissao": profissao,
            "senha": senha,
            "cep": cepInp,
            "estado": estado,
            "cidade": cidade,
            "bairro": bairro,
            "rua": rua,
            "numero": Number(numero),
            "data_nascimento": dataNascimento,
            "telefone": telefone,
            "whatsapp": whatsapp,
            "pai":pai,
            "mae":mae,
            "email":email
          });
        
          

          var config: any = {
            method: 'put',
            headers: { 
              'x-access-token': token, 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
         
          api.put('/v1/clientes/'+id+'', data, config).then(function(response)
          {
                setErro(false);
                setErroMsg("");
                dataDoc(response.data.cliente);
                history.push("/clientes");
          }).catch(function(error)
          {
                setErro(true);
                setErroMsg("Ocorreu um Erro");
          })
    }

    const dataDoc = (cliente:any)=>
    {
        io.emit('data-doc', {cliente: cliente})
    }

    const cpfCnpjMask = (cpfcnpj:any)=>
    {
        cpfcnpj = cpfcnpj.replace(/\D/g, "")
        if(cpfcnpj.length <= 11)
        {
            cpfcnpj = cpfcnpj.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
        }else
        {
           cpfcnpj = cpfcnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5");
        }
        return cpfcnpj;
    }

    const logout = ()=>
    {
        auth.logout();
    }

    return (
        <div className="fundo">
            <input type="checkbox" checked={selected.selected} onChange={checkboxChange} id="sidebar-toogle"></input>
            <SideBar>
                <li>
                    <a href="#" onClick={logout}>
                        <span className="fas fa-sign-out-alt"></span>
                        <span>Sair</span>
                    </a>
                </li>
            </SideBar>
            <MainContent title="Editar Cliente" tipoAcesso={auth.getTipoAcesso()} permissionOne={true} permissionTwo={true} permissionThree={false}>
            <Container>
                    <Button variant="primary" onClick={()=>navigation("/clientes")}>Voltar</Button><br /><br />
                    {(erro) ?
                        <Alert variant="danger">
                            {erroMsg}
                        </Alert>
                    : null}
                    <Form onSubmit={handleSubmit}>
                        <div className="text-center">
                            <h3>Dados Pessoais do Cliente</h3>
                        </div>
                        <Form.Group>
                            <Form.Label>Nome: </Form.Label>
                            <Form.Control required type="text" name="nome" value={nome} onChange={handleChange} placeholder="Nome do Cliente"/>
                        </Form.Group>
                        <Form.Group>
                                <Form.Label>Nome do pai: </Form.Label>
                                <Form.Control type="text" value={pai} name="nome_pai" onChange={handleChange} placeholder="Nome do pai"/>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Nome da Mãe: </Form.Label>
                                <Form.Control type="text" value={mae} name="nome_mae" onChange={handleChange} placeholder="Nome da mãe"/>
                            </Form.Group>
                        <Form.Group>
                                <Form.Label>Data de Nascimento:</Form.Label>
                                <Form.Control required type="text" name="data_nascimento" id="dataNascimento" value={dataNascimento} onChange={handleChange} placeholder="Data de nascimento"/>
                        </Form.Group>
                        <Form.Group>
                                <Form.Label>Nacionalidade:</Form.Label>
                                <Form.Control required type="text" name="nacionalidade" id="nacionalidade" value={nacionalidade} onChange={handleChange} placeholder="Nacionalidade"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>E-mail:</Form.Label>
                            <Form.Control type="email" name="email" id="email" value={email} onChange={handleChange} placeholder="E-mail"/>
                        </Form.Group>
                        <Form.Group>
                                <Form.Label>Telefone:</Form.Label>
                                <Form.Control type="text" name="telefone" id="telefone" value={telefone} onChange={handleChange} placeholder="Telefone"/>
                        </Form.Group>
                        <Form.Group>
                                <Form.Label>Whatsapp:</Form.Label>
                                <Form.Control type="text" name="whatsapp" id="whatsapp" value={whatsapp} onChange={handleChange} placeholder="Whatsapp"/>
                        </Form.Group>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>CPF/CNPJ:</Form.Label>
                                <Form.Control required  onKeyUp={handleKeyUpCPF} onFocus={retirarFormatacao} onBlur={formatarCampo} minLength={11} maxLength={14} id="cpfcnpj" placeholder="CPF ou CNPJ do Cliente"/>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>RG:</Form.Label>
                                <Form.Control required type="text" name="rg" onChange={handleChange} value={rg} placeholder="RG do Cliente"/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Group>
                            <Form.Label>Estado Civil:</Form.Label>
                            <Form.Control required name="estado_civil" value={estadoCivil} defaultValue={estadoCivil} onChange={handleChange} as="select">
                                <option disabled value="">Selecione uma Opção</option>
                                <option value="Solteiro(a)">Solteiro(a)</option>
                                <option value="Separado(a)">Separado(a)</option>
                                <option value="Divorciado(a)">Divorciado(a)</option>
                                <option value="Casado(a)">Casado(a)</option>
                                <option value="União Estável">União Estável</option>
                                <option value="Viúvo(a)">Viúvo(a)</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Profissão: </Form.Label>
                            <Form.Control type="text" name="Profisao" value={profissao || ""} onChange={handleChange} placeholder="Profissão do Cliente"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Senha: </Form.Label>
                            <Form.Control type="password" name="Senha" onChange={handleChange} placeholder="Senha"/>
                        </Form.Group>
                        <div className="text-center">
                            <h3>Endereço do Cliente</h3>
                        </div>
                        <Form.Group>
                            <Form.Label>CEP:</Form.Label>
                            <Form.Control required onKeyUp={handleCepKeyUp} name="cep" id="cep" placeholder="CEP" />
                        </Form.Group>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label>Estado:</Form.Label>
                                <Form.Control required type="text" name="estado" value={estado} onInput={handleChangeEstado} placeholder="Estado"/>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Cidade:</Form.Label>
                                <Form.Control required type="text" value={cidade} name="cidade" onInput={handleChangeCidade}  placeholder="Cidade"/>
                            </Form.Group>
                        </Form.Row>
                        <Form.Group>
                            <Form.Label>Bairro:</Form.Label>
                            <Form.Control required type="text" name="bairro" value={bairro} onChange={handleChange} placeholder="bairro"/>
                        </Form.Group>
                        <div className="form-row">
                            <Form.Group className="col-sm-11">
                                <Form.Label>Rua:</Form.Label>
                                <Form.Control required type="text" name="rua" value={rua} onChange={handleChange} placeholder="rua"/>
                            </Form.Group>
                            <Form.Group className="col-sm-1">
                                <Form.Label>Número:</Form.Label>
                                <Form.Control required type="text" name="numero" value={numero} onChange={handleChange} placeholder="Número"/>
                            </Form.Group>
                        </div>
                        <Button type="submit" variant="primary">Salvar</Button>
                    </Form>
                </Container>
            </MainContent>
        </div>
    )
}

export default EditCliente;