import React, {useEffect, useState, useContext} from 'react';
import './styles.css';
import SideBar from '../../components/AppComponents/SideBar'
import MainContent from '../../components/AppComponents/MainContent';
import {Container, Table, Button} from 'react-bootstrap';
import auth from '../../services/auth';
import api from '../../services/api';
import { useHistory  } from 'react-router-dom';
import {SideBarContext} from '../../providers/SideBarStatus';

function Usuarios()
{

    const history = useHistory();

    const [listUsers, setListUsers] = useState([])
    const [atualUser, setAtualUser] = useState({
        name: String,
        user: String,
        email: String,
        tipo: String,
        id: String
    });


    const {selected, setSelected}: any = useContext(SideBarContext);

    const navigation = (route: any)=>
    {
        history.push(route);
    }


    useEffect(()=>{
        const token = auth.getCookie('token');
        listAllUsers(token);
        getUserLogado();
    }, [])

    

    const deleteUser = async (id:any, token:any)=>
    {
        //const response = await api.post('/adminUsers/'+token_base64+'/delete/'+atualUser.id+'', {data: id})

        const response = await api.delete('/v1/users/'+id+'/?token='+token+'')
        window.location.reload()
        //setListUsers(response.data.data);
    }

    const getUserLogado = ()=>
    {
        const userLogado:any = auth.getUserLogado();
       
        setAtualUser(userLogado);
    }

    const listAllUsers = async (token: any)=>
    {
        

        const userLogado = auth.getUserLogado();


        //const response = await api.get('/adminUsers/'+token+'/listAll/'+userLogado.id+'');

        const response = await api.get('/v1/users/?token='+token+'')
        console.log(response.data.data)
        setListUsers(response.data.data);
    }

    const logout = ()=>
    {
        auth.logout();
    }

    const checkboxChange = (e: any)=>
    {
        if(selected.selected === false)
        {
            setSelected({
                selected: true
            })
        }else
        {
            setSelected({
                selected: false
            })
        }
    }

    return (
        <div className="fundo">
            <input type="checkbox" checked={selected.selected} onChange={checkboxChange} id="sidebar-toogle"></input>
            <SideBar>
                <li>
                    <a href="#" onClick={logout}>
                        <span className="fas fa-sign-out-alt"></span>
                        <span>Sair</span>
                    </a>
                </li>
            </SideBar>
            <MainContent title="Usuários" tipoAcesso={auth.getTipoAcesso()} permissionOne={true} permissionTwo={false} permissionThree={false}>
                <Container>
                    <Button variant="primary" onClick={()=>navigation('/adduser')}>Adicionar</Button><br /><br />
                    <Table striped bordered variant="dark">
                        <thead>
                            <tr>
                                <th>id</th>
                                <th>Nome</th>
                                <th>Email</th>
                                <th>user</th>
                                <th>Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listUsers.map((user, index)=>(
                                <tr key={index}>
                                    <td>{user['id']}</td>
                                    <td>{user['nome']}</td>
                                    <td>{user['email']}</td>
                                    <td>{user['user']}</td>
                                    <td><Button variant="primary" onClick={()=>navigation('/edituser/'+user["id"]+'')}>Editar</Button> / <Button onClick={()=>deleteUser(user['id'], auth.getCookie('token'))} variant="danger">Deletar</Button></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Container>
            </MainContent>
        </div>
    )
}

export default Usuarios;