import React, {useEffect, useState, useContext} from 'react';
import { SideBarContext } from '../../../providers/SideBarStatus';
import { useHistory } from 'react-router';
import auth from '../../../services/auth';
import SideBar from '../../../components/AppComponents/SideBar';
import MainContent from '../../../components/AppComponents/MainContent';
import { Alert, Button, Container, Form} from 'react-bootstrap';
import api from '../../../services/api';
import './styles.css';
import EditorJS from '../../../components/EditorJS';
import { EditorContext } from '../../../providers/Editor';
import $ from 'jquery';
import 'jquery-mask-plugin'
import socket from 'socket.io-client';
import getApiUrl from '../../../services/GetApiUrl';


const io = socket(getApiUrl(), {transports:["websocket"]})

function AddProcesso()
{
    const {selected, setSelected}: any = useContext(SideBarContext);
    const {valor, setValor}:any = useContext(EditorContext);
    

    const [erro, setErro] = useState(false);
    const [erroMsg, setErroMsg] = useState("");
    const [cliente, setCliente] = useState({
        bairro: "",
        cep: "",
        cidade: "",
        nacionalidade: "",
        cpf: "",
        estado: "",
        estado_civil: "",
        id: 0,
        nome: "",
        numero: 0,
        profissao: "",
        rg: "",
        rua: "",
        mae:"",
        pai:""
    });


    const [executado, setExecutado] = useState("")
    const [estadoTribunalInstancia, setEstadoTribunalInstancia] = useState("")
    const [advogado, setAdvogado] = useState("")
    const [forma_pagamento, setFormaPagamento] = useState("");
    const [tipo_processo, setTipoProcesso] = useState("");
    const [data_inicial, setDataInicial] = useState("");
    const [ativo, setAtivo] = useState(0);
    const [preco, setPreco] = useState("");
    const [precoContrato, setPrecoContrato] = useState("")
    const [precoContratoDoc, setPrecoContratoDoc] = useState("")
    const [fiador, setFiador] = useState("")
    const [editorValue, setEditorValue] = useState("")
    const [numero, setNumero] = useState("")
    const [id_processo, setIdProcesso] = useState(0)
    const [rel_autor, setRelAutor] = useState("")


    const history = useHistory();

    useEffect(()=>{
        loadMask();
        getIdProcesso()
        io?.on('connect', () => console.log('[IO] Connect => A new connection has been established'))
    },[])

    const getIdProcesso = async()=>
    {
        const res = await api.get("/v1/staticsProcessos", {
            headers: { 
                'x-access-token': auth.getCookie('token'), 
                'Content-Type': 'application/json'
            },
        })

        setIdProcesso(res.data.id_processo + 1)
    }

    const checkboxChange = (e: any)=>
    {
        if(selected.selected === false)
        {
            setSelected({
                selected: true
            })
        }else
        {
            setSelected({
                selected: false
            })
        }
    }

    const navigation = (route: any)=>
    {
        history.push(route);
    }

    const logout = ()=>
    {
        auth.logout();
    }

    const handleSubmit = async (e: React.FormEvent)=>
    {
        e.preventDefault();


        if(cliente.id != 0 && cliente.id != null)
        {
            var data = JSON.stringify({
                "id_cliente": cliente.id,
                "numero_processo": numero,
                "advogado": advogado,
                "instancia": estadoTribunalInstancia,
                "tipo_processo": tipo_processo,
                "data_inicial": data_inicial,
                "cpf_exequente": cliente.cpf,
                "executado": executado,
                "movimentacoes": valor,
                "forma_pagamento": forma_pagamento,
                "valor": parseFloat(preco).toFixed(2),
                "valor_contrato":parseFloat(precoContrato).toFixed(2),
                "fiador":fiador,
                "file": cliente.id+"-"+cliente.nome+"_administrativos.doc",
                "relAutor":rel_autor,
                "ativo": ativo
            });
    
            var config: any = {
                method: 'post',
                headers: { 
                  'x-access-token': auth.getCookie('token'), 
                  'Content-Type': 'application/json'
                },
                data : data
            };
    

            api.post('/v1/processos/', data, config).then(function (response)
            {
                if(response.data.msg == "Processo Cadastrado com sucesso.")
                {
                    setValor("")
                    navigation("/processo");
                }
            }).catch(function (error) 
            {
                console.log(error);
            });
        }else
        {
            setErro(true)
            setErroMsg("Dados do Exequente Inválidos.")
        }
        
    }


    const loadMask = ()=>
    {
        $("#preco").mask("#.##0,00", {reverse: true})
        $("#preco").on('change', function(){
            setPreco(formataDouble($(this).val()))
        });
        $("#preco_contrato").mask("#.##0,00", {reverse: true})
        $("#preco_contrato").on('change', function(){
            setPrecoContrato(formataDouble($(this).val()))
        });
        $("#dataInicial").mask("00/00/0000");
        //$("#cpfExequente").mask("000.000.000-00")
    }

    const handleChange = (e: any)=>
    {
        const target = e.target;

        switch(target.name)
        {
            case "numero":
                setNumero(target.value)
                break;
            case "advogado":
                setAdvogado(target.value)
                break;    
            case "executado":
                setExecutado(target.value);
                break;
            case "estado_tribunal_instancia":
                setEstadoTribunalInstancia(target.value);
                break;
            case "forma_pagamento":
                setFormaPagamento(target.value)
                break;
            case "fiador":
                setFiador(target.value)
                break
            case "tipo_processo":
                setTipoProcesso(target.value);
                break;
            case "data_inicial":
                setDataInicial(target.value);
                break;
            case "ativo":
                (target.checked == true) ? setAtivo(target.value) : setAtivo(0);
                break;  
            case "rel_autor":
                setRelAutor(target.value)
                break   
        }
    }


    const handleKeyUpCPF = async (e: React.FormEvent<HTMLInputElement>)=>
    {
        let value = e.currentTarget.value;
        value = value.replace(/\D/g, "")
        if(value.length === 11)
        {
            const token = auth.getCookie('token')
            try {
                const response = await api.get('/v1/clientes/getclientcpf/'+value+'', 
                {
                    headers: {
                        'x-access-token': token,
                        'Content-Type': 'application/json'
                    }
                })
                setErro(false)
                setErroMsg("")

                const {data} = response
                console.log(data)
                setCliente(data)
            } catch (error:any) 
            {
                const erro = error.toString()
                const format_erro = erro.split('Error: ').join('')
                if(format_erro === 'Request failed with status code 404')
                {
                    setErro(true)
                    setErroMsg("Exequente não Encontrado.");
                    setCliente({
                        bairro: "",
                        cep: "",
                        cidade: "",
                        nacionalidade: "",
                        cpf: "",
                        estado: "",
                        estado_civil: "",
                        id: 0,
                        nome: "",
                        numero: 0,
                        profissao: "",
                        rg: "",
                        rua: "",
                        mae:"",
                        pai:""
                    })
                }
            }
        }else if(value.length === 14)
        {
            
            const token = auth.getCookie('token')
            try {
                const response = await api.get('/v1/clientes/getclientcpf/'+value+'', 
                {
                    headers: {
                        'x-access-token': token,
                        'Content-Type': 'application/json'
                    }
                })
                setErro(false)
                setErroMsg("")

                const {data} = response
                console.log(data)
                setCliente(data)
            } catch (error:any) 
            {
                const erro = error.toString()
                const format_erro = erro.split('Error: ').join('')
                if(format_erro === 'Request failed with status code 404')
                {
                    setErro(true)
                    setErroMsg("Exequente não Encontrado.");
                    setCliente({
                        bairro: "",
                        cep: "",
                        cidade: "",
                        nacionalidade: "",
                        cpf: "",
                        estado: "",
                        estado_civil: "",
                        id: 0,
                        nome: "",
                        numero: 0,
                        profissao: "",
                        rg: "",
                        rua: "",
                        mae:"",
                        pai:""
                    })
                }
            }
        }
        else
        {
            setCliente({
                bairro: "",
                cep: "",
                cidade: "",
                nacionalidade: "",
                cpf: "",
                estado: "",
                estado_civil: "",
                id: 0,
                nome: "",
                numero: 0,
                profissao: "",
                rg: "",
                rua: "",
                mae:"",
                pai:""
            })
        }
    }


    /*const mascaraCPF = (cpf:any)=>
    {
        console.log(cpf)
        if(cpf != "")
        {
            cpf=cpf.replace(/\D/g,"");
            cpf=cpf.replace(/(\d{3})(\d)/,"$1.$2");
            cpf=cpf.replace(/(\d{3})(\d)/,"$1.$2");
            cpf=cpf.replace(/(\d{3})(\d{1,2})$/,"$1-$2")
            return cpf;
        }else
        {
            return "";
        }
    }*/

    const mascaraCPFCNPJ = (cpfcnpj:any)=>
    {
        if(cpfcnpj != "")
        {
            if(cpfcnpj.length <= 11)
            {
                cpfcnpj=cpfcnpj.replace(/\D/g,"");
                cpfcnpj=cpfcnpj.replace(/(\d{3})(\d)/,"$1.$2");
                cpfcnpj=cpfcnpj.replace(/(\d{3})(\d)/,"$1.$2");
                cpfcnpj=cpfcnpj.replace(/(\d{3})(\d{1,2})$/,"$1-$2")
            }else
            {
                cpfcnpj=cpfcnpj.replace(/\D/g,"");
                cpfcnpj = cpfcnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5");
            }
            return cpfcnpj;
        }else
        {
            return "";
        }
    }

    const vereficaValue = ()=>
    {
        if(cliente.nome !== "")
        {
            return true;
        }else
        {
            return false;
        }
    }

    


    const formataDouble = (value:any)=>
    {
        value = value.split(".").join("");
        value = value.split(",").join(".");

        return value;
    }

    
    const formatarCampo = (e: React.FormEvent<HTMLInputElement>)=>
    {
        let value = e.currentTarget.value

        if(value.length <= 11)
        {
            e.currentTarget.value = mascaraCpf(value)
        }else
        {
            e.currentTarget.value = mascaraCnpj(value)
        }

    }

    const retirarFormatacao = (e: React.FormEvent<HTMLInputElement>)=>
    {
        e.currentTarget.value = e.currentTarget.value.replace(/(\.|\/|\-)/g,"");
    }

    const mascaraCpf = (valor:any)=>
    {
        return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"\$1.\$2.\$3\-\$4");
    }

    const mascaraCnpj = (valor:any)=>
    {
        return valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5");
    }


    return (
        <div className="fundo">
            <input type="checkbox" checked={selected.selected} onChange={checkboxChange} id="sidebar-toogle"></input>
            <SideBar>
                <li>
                    <a href="#" onClick={logout}>
                        <span className="fas fa-sign-out-alt"></span>
                        <span>Sair</span>
                    </a>
                </li>
            </SideBar>
            <MainContent title="Adicionar Processos" tipoAcesso={auth.getTipoAcesso()} permissionOne={true} permissionTwo={false} permissionThree={false}>
                <Container>
                    <Button onClick={()=>navigation("/processo")}>Voltar</Button><br /><br />
                    {(erro) ?
                        <Alert variant="danger">
                            {erroMsg}
                        </Alert>
                    : null}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Label>Número do Processo: </Form.Label>
                            <Form.Control required type="text" onChange={handleChange} name="numero" placeholder="Número do Processo"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Tipo de Processo: </Form.Label>
                            <Form.Control required type="text" onChange={handleChange} name="tipo_processo" placeholder="Tipo de Processo"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Data Inicial: </Form.Label>
                            <Form.Control required type="text" onChange={handleChange} name="data_inicial" id="dataInicial" placeholder="Data Inicial"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Advogado: </Form.Label>
                            <Form.Control required type="text" onChange={handleChange} name="advogado" placeholder="advogado"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Estado / Tribunal / Instância:</Form.Label>
                            <Form.Control required type="text" onChange={handleChange} name="estado_tribunal_instancia" placeholder="Estado / Tribunal / Instância"/>
                        </Form.Group>
                        {(vereficaValue()) ?
                        <div className="dadosexquente">
                            <div className="text-center">
                                <h3>{cliente.nome}</h3>
                            </div>
                            <div>
                                <p><strong>RG: </strong>{cliente.rg}</p>
                                <p><strong>CPF/CNPJ: </strong>{mascaraCPFCNPJ(cliente.cpf)}</p>
                                <p><strong>cidade: </strong>{cliente.cidade} - {cliente.estado}</p>
                            </div>
                        </div>
                        :null}
                        <Form.Group>
                            <Form.Label>Cliente: </Form.Label>
                            <Form.Control onKeyUp={handleKeyUpCPF} onFocus={retirarFormatacao} onBlur={formatarCampo} minLength={11} maxLength={14} id="cpfExequente" name="cpf_exequente" placeholder="cpf do cliente"/>
                        </Form.Group>
                        <Form.Group>
                                <Form.Label>Réu/Autor:</Form.Label>
                                <Form.Control required name="rel_autor" defaultValue={""} onChange={handleChange} as="select">
                                    <option disabled value="">Selecione uma Opção</option>
                                    <option value="Réu">Réu</option>
                                    <option value="Autor">Autor</option>
                                </Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Parte Contrária: </Form.Label>
                            <Form.Control required type="text" name="executado" onChange={handleChange} placeholder="Parte Contrária"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Últimas Movimentações: </Form.Label>
                            <EditorJS />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Forma de Pagamento: </Form.Label>
                            <Form.Control required type="text" onChange={handleChange} name="forma_pagamento" placeholder="Forma de Pagamento"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Valor da Causa: </Form.Label>
                            <input type="text" className="form-control" name="preco" id="preco" placeholder="Ex: 1,00" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Valor do Contrato: </Form.Label>
                            <input type="text" className="form-control" name="preco_contrato" id="preco_contrato"  placeholder="Ex: 1,00" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Fiador: </Form.Label>
                            <input className="form-control" name="fiador" id="fiador" onKeyUp={handleChange} placeholder="Fiador"/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Check
                                name="ativo"
                                onChange={handleChange}
                                label="Baixado"
                                value="1"
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit">Adicionar</Button>
                    </Form>
                </Container>
            </MainContent>
        </div>
    )
}

export default AddProcesso;