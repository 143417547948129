import React, {useEffect, useState} from 'react';
import './styles.css';
import {Link, useLocation, useParams} from 'react-router-dom';
import '../../FontAwesomeIcons'
import auth from '../../../services/auth';
import PrivateLink from './PrivateLink'


function SideBar(props: any)
{

    const {pathname} = useLocation();
    const params = useParams();
    const [tipoAcesso, setTipoAcesso] = useState(0);

    useEffect(()=>{
        const tipo = auth.getTipoAcesso()
        setTipoAcesso(tipo)
    },[])

    interface RouteParams {
        id: string,
        id_processo: string,
        id_audiencias: string,
        id_lista_telefone: string,
        id_valor_do_contrato:string
    }

    const {id, id_processo, id_audiencias, id_lista_telefone, id_valor_do_contrato} = useParams<RouteParams>();

    const {children} = props;

    
    return (
        <div className="sidebar">
            <div className="sidebar-header">
                <h3 className="brand">
                    <span className="fas fa-balance-scale"></span> 
                    <span> Sis Adv</span>
                </h3> 
                <label htmlFor="sidebar-toogle" className="ti-menu-alt sidebar-toogle-label"></label>
            </div>
            <div  className="sidebar-menu">
                <ul>
                    <li>
                        <Link className={pathname == '/' ? 'ativo' : ''} to="/">
                            <span className="fas fa-home"></span>
                            <span>Início</span>
                        </Link>
                    </li>
                    <PrivateLink tipoAcesso={tipoAcesso} permissionOne={true} permissionTwo={false} permissionThree={false}>
                        <Link className={(pathname == '/usuarios' || pathname == '/adduser' || pathname == '/edituser/'+id+'') ? 'ativo' : ''} to='/usuarios'>
                            <span className="fas fa-users"></span>
                            <span>Usuários</span>
                        </Link>
                    </PrivateLink>
                    <PrivateLink tipoAcesso={tipoAcesso} permissionOne={true} permissionTwo={true} permissionThree={false}>
                        <Link to="/clientes" className={(pathname == '/clientes' || pathname == '/addcliente' || pathname == '/editCliente/'+id+'') ? 'ativo' : ''}>
                            <span className="fas fa-clipboard-list"></span>
                            <span>Clientes</span>
                        </Link>
                    </PrivateLink>
                    <PrivateLink tipoAcesso={tipoAcesso} permissionOne={true} permissionTwo={false} permissionThree={false}>
                        <Link to="/processo" className={(pathname == '/processo' || pathname == '/addProcesso' || pathname == '/editProcesso/'+id_processo+'') ? 'ativo' : ''}>
                            <span className="fas fa-balance-scale"></span>
                            <span>Processos</span>
                        </Link>
                    </PrivateLink>
                    <PrivateLink tipoAcesso={tipoAcesso} permissionOne={true} permissionTwo={true} permissionThree={true}>
                        <Link to="/audiencias" className={(pathname == '/audiencias' || pathname == '/addAudiencias' || pathname == '/editAudiencia/'+id_audiencias+'') ? 'ativo' : ''}>
                            <span className="fas fa-balance-scale"></span>
                            <span>Prazos / Audiências</span>
                        </Link>
                    </PrivateLink>
                    <PrivateLink tipoAcesso={tipoAcesso} permissionOne={true} permissionTwo={false} permissionThree={false}>
                        <Link to="/valor-do-contrato" className={(pathname == '/valor-do-contrato' || pathname == '/valor-do-contratoAdd' || pathname == '/valor-do-contratoEdit/'+id_valor_do_contrato+'') ? 'ativo' : ''}>
                            <span className="fas fa-dollar-sign"></span>
                            <span>Valor do Contrato</span>
                        </Link>
                    </PrivateLink>
                    <PrivateLink tipoAcesso={tipoAcesso} permissionOne={true} permissionTwo={true} permissionThree={true}>
                        <Link to="/listatelefone" className={(pathname == '/listatelefone' || pathname == '/listatelefoneAdd' || pathname == '/listatelefoneEdit/'+id_lista_telefone+'') ? 'ativo' : ''}>
                            <span className="fas fa-list"></span>
                            <span>Lista Telefones</span>
                        </Link>
                    </PrivateLink>
                    {children}
                </ul>
            </div>
        </div>
    );
}

export default SideBar;