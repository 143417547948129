import React, {useEffect, useState, useContext } from 'react'
import SideBar from '../../components/AppComponents/SideBar';
import auth from '../../services/auth';
import { useHistory } from 'react-router';
import {SideBarContext} from '../../providers/SideBarStatus';
import MainContent from '../../components/AppComponents/MainContent';
import { Button, Container, Table } from 'react-bootstrap';
import api from '../../services/api';
import './styles.css'


function ValorDoContrato()
{

    
    const {selected, setSelected}:any = useContext(SideBarContext)

    const history = useHistory()

    const [listValorDoContrato, setValoresDoContrato] = useState([])
    const [statusModal, setStatusModal] = useState(false)
    const [valorDaCausaAtual, setValorDaCausaAtual] = useState({
        numero_processo:String,
        parcelas:[]
    })


    useEffect(()=>{
        const token = auth.getCookie("token")
        listAllValoresDaCausa(token)
    },[])

    const listAllValoresDaCausa = async(token:any)=>
    {
        const response = await api.get('/v1/valordocontrato/listAll', {
            headers: { 
                'x-access-token': token, 
                'Content-Type': 'application/json'
            },
        })

        setValoresDoContrato(response.data)
    }

    const checkboxChange = ()=>
    {
        if(selected.selected === false)
        {
            setSelected({
                selected: true
            })
        }else
        {
            setSelected({
                selected: false
            })
        }
    }


    const navigation = (route:any)=>
    {
        history.push(route)
    }

    const mascaraCPF = (cpf:any)=>
    {
        if(cpf !== "")
        {
            cpf=cpf.replace(/\D/g,"");
            cpf=cpf.replace(/(\d{3})(\d)/,"$1.$2");
            cpf=cpf.replace(/(\d{3})(\d)/,"$1.$2");
            cpf=cpf.replace(/(\d{3})(\d{1,2})$/,"$1-$2")
            return cpf;
        }else
        {
            return "";
        }
    }


    const showParcelas = (valorDaCausa:any)=>
    {
        setStatusModal(true)
        setValorDaCausaAtual(valorDaCausa)
    }

    const escoderModal = ()=>
    {
        setStatusModal(false)
        setValorDaCausaAtual({
            numero_processo:String,
            parcelas:[]
        })
    }


    const exibirModal = ()=>{
        if(statusModal === true)
        {
            return (
                <div>
                    <div className='mymodal'>
                        <div>
                            <button title="Fechar" className='fechar' onClick={()=>escoderModal()}>x</button>
                            <h2>{valorDaCausaAtual['numero_processo']}</h2><br></br>
                            <p>total de parcelas {valorDaCausaAtual['parcelas'].length}</p><br></br>
                            <Table variant='dark'>
                                <thead>
                                    <tr>
                                         <th>valor da parcela</th>
                                        <th>data de Vencimento</th>
                                    </tr>
                                </thead>
                               <tbody>
                                    {valorDaCausaAtual["parcelas"].map((parcela, index)=>(
                                        <tr key={index}>
                                            <td>{formataPreco(parcela["valor_parcela"])}</td>
                                            <td>{parcela['data_vencimento']}</td>
                                        </tr>
                                    ))}
                               </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            )
        }else
        {
            return null
        }
    }


    const logout = ()=>
    {
        auth.logout();
    }

    const deletar = (id:any)=>
    {
        const config = {
            headers: { 
                'x-access-token': auth.getCookie("token"), 
                'Content-Type': 'application/json'
            },
        }

        api.delete("/v1/valordocontrato/delete/"+id+"", config).then(res=>{
            const token = auth.getCookie("token")
            listAllValoresDaCausa(token)
        })
        .catch(err=>
        {

        })
    }

    return (
        <div className="fundo">
             <input type="checkbox" checked={selected.selected} onChange={checkboxChange} id="sidebar-toogle"></input>
             <SideBar>
                <li>
                    <a href="#" onClick={logout}>
                        <span className="fas fa-sign-out-alt"></span>
                        <span>Sair</span>
                    </a>
                </li>
            </SideBar>
            <MainContent title="Valor do Contrato" tipoAcesso={auth.getTipoAcesso()} permissionOne={true} permissionTwo={false} permissionThree={false}>
                <Container>
                    <Button onClick={()=>navigation("/valor-do-contratoAdd")}>Adicionar</Button><br /><br />
                    <Table striped bordered variant="dark">
                        <thead>
                            <tr>
                                <th>id</th>
                                <th>N° Processo</th>
                                <th>cliente</th>
                                <th>CPF Cliente</th>
                                <th>Valor Total</th>
                                <th>Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listValorDoContrato.map((valorDoContrato, index)=>(
                                <tr key={index}>
                                    <td>{valorDoContrato["id"]}</td>
                                    <td>{valorDoContrato["numero_processo"]}</td>
                                    <td>{valorDoContrato["nome"]}</td>
                                    <td>{mascaraCPF(valorDoContrato['cpf'])}</td>
                                    <td>{formataPreco(valorDoContrato['valor_total'])}</td>
                                    <td><Button onClick={()=>showParcelas(valorDoContrato)} variant='success'>Ver Parcelas</Button> | <Button onClick={()=>navigation("/valor-do-contratoEdit/"+valorDoContrato['id']+"")}>Editar</Button> | <Button onClick={()=>deletar(valorDoContrato['id'])} variant='danger'>Deletar</Button></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Container>
            </MainContent>
            {exibirModal()}
            
        </div>
    )
}

export default ValorDoContrato

function formataPreco(valorTotal: any): React.ReactNode 
{
    const valorFormatdo = valorTotal.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})

    return valorFormatdo
}
