import React, {useEffect, useState, useContext} from 'react';
import { Container } from 'react-bootstrap';
import SideBar from '../../components/AppComponents/SideBar';
import {SideBarContext} from '../../providers/SideBarStatus';
import auth from '../../services/auth'
import MainContent from '../AppComponents/MainContent';

function NotFound()
{

    const {selected, setSelected}: any = useContext(SideBarContext);

    const checkboxChange = (e: any)=>
    {
        if(selected.selected === false)
        {
            setSelected({
                selected: true
            })
        }else
        {
            setSelected({
                selected: false
            })
        }
    }

    const logout = ()=>
    {
        auth.logout();
    }


    return (
        <div className="fundo">
            <input type="checkbox" checked={selected.selected} onChange={checkboxChange} id="sidebar-toogle"></input>
            <SideBar>
                <li>
                    <a href="#" onClick={logout}>
                        <span className="fas fa-sign-out-alt"></span>
                        <span>Sair</span>
                    </a>
                </li>
            </SideBar>
            <MainContent  title="Error 404" tipoAcesso={auth.getTipoAcesso()} permissionOne={true} permissionTwo={true} permissionThree={true}>
                <Container>
                    <p>Página não encontrada.</p>
                </Container>
            </MainContent>
        </div>
    )
}

export default NotFound;