import React, {useState} from 'react';

export const EditorContext = React.createContext({})

export const EditorProvider = (props:any)=>
{
    const [valor, setValor] = useState({
        valor: ""
    })

    return (
        <EditorContext.Provider value={{valor, setValor}}>
            {props.children}
        </EditorContext.Provider>
    )
}