import React, {useEffect, useState, useContext} from 'react';
import './styles.css';
import auth from '../../../services/auth';
import SideBar from '../../../components/AppComponents/SideBar';
import MainContent from '../../../components/AppComponents/MainContent';
import { Alert, Button, Container, Form } from 'react-bootstrap';
import base64 from 'base-64';
import api from '../../../services/api';
import {useHistory, useParams} from 'react-router-dom';
import {SideBarContext} from '../../../providers/SideBarStatus';
import axios from 'axios';

const EditUser = ()=>
{

    const [name, setName] = useState("");
    const [user, setUser] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [profision, setProfision] = useState("");
    const [userid, setUserId] = useState("");
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const {selected, setSelected}: any = useContext(SideBarContext);
    
    const history = useHistory();

    interface RouteParams {
        id: string
    }

    const {id} = useParams<RouteParams>();


    useEffect(()=>{
        const token = auth.getCookie('token');
        getUserEditer(token);
    }, []);


    const getUserEditer = async(token: any)=>
    {
        const response = await api.get('/v1/users/'+id+'/?token='+token+'')
        const data = response.data;

        setName(data.nome)
        setUser(data.user)
        setEmail(data.email)
        setProfision(data.tipo)
        setUserId(data.id)
    }

    const logout = ()=>
    {
        auth.logout();
    }

    const handleChange = (event: any)=>
    {
        const target = event.target;

        switch(target.name)
        {
            case "name":
                setName(target.value);
                break;
            case "user":
                setUser(target.value);
                break;
            case "email":
                setEmail(target.value)
                break;
            case "password":
                setPassword(target.value)
                break;
            case "profission":
                setProfision(target.value)
                break;            
        }

    }

    const submitForm = (event: any)=>
    {
        event.preventDefault();
        
       update(auth.getCookie('token'));

    }


    const navigation = (route: any)=>
    {
        history.push(route);
    }

    const update = async (token:any)=>
    {

        var data = JSON.stringify({
            "nome": name,
            "email": email,
            "user": user,
            "senha": password,
            "tipo": Number(profision)
          });

        var config:any = {
            method: 'put',
            url: 'https://pelucio.api.pelucioadvocacia.adv.br/v1/users/'+id+'',
            headers: { 
              'x-access-token': token, 
              'Content-Type': 'application/json'
            },
            data : data
          };  

          axios(config).then(function (response) 
          {
              const {msg} = response.data
              if(msg == "Usuário atualizado com sucesso")
              {
                  setError(false)
                  navigation("/usuarios");
              }
          }).catch(function (error) 
          {
            setError(true)
            setErrorMsg("Ocorreu um erro ao atualizar")
          });

    }

    const checkboxChange = (e: any)=>
    {
        if(selected.selected === false)
        {
            setSelected({
                selected: true
            })
        }else
        {
            setSelected({
                selected: false
            })
        }
    }

    return (
        <div className="fundo">
            <input type="checkbox" checked={selected.selected} onChange={checkboxChange} id="sidebar-toogle"></input>
            <SideBar>
                <li>
                    <a href="#" onClick={logout}>
                        <span className="fas fa-sign-out-alt"></span>
                        <span>Sair</span>
                    </a>
                </li>
            </SideBar>
            <MainContent title="Editar Usuário" tipoAcesso={auth.getTipoAcesso()} permissionOne={true} permissionTwo={false} permissionThree={false}>
            <Container>
                <Button onClick={()=>{history.push("/usuarios")}}>Voltar</Button><br /><br />
                    {error == true ? <Alert variant="danger">{errorMsg}</Alert> : ''}
                    <Form onSubmit={submitForm}>
                        <Form.Group>
                            <Form.Label>Nome:</Form.Label>
                            <Form.Control onChange={handleChange} name="name" value={name} required type="text" placeholder="Nome do Usuário" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>User:</Form.Label>
                            <Form.Control onChange={handleChange} name="user" value={user} required type="text" placeholder="Login do Usuário" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Senha:</Form.Label>
                            <Form.Control onChange={handleChange} name="password" type="password" placeholder="Senha do Usuário" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>E-mail:</Form.Label>
                            <Form.Control onChange={handleChange} required name="email" value={email} type="email" placeholder="E-mail do usuário" />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Função:</Form.Label>
                            <Form.Control value={profision} required name="profission" onChange={handleChange} as="select">
                                <option value="" disabled>Selecione uma Opção</option>
                                <option value="1">Advogado</option>
                                <option value="2">Secretário</option>
                            </Form.Control>
                        </Form.Group>
                        <Button variant="primary" type="submit">Editar</Button>
                    </Form>
                </Container>
            </MainContent>
        </div>
    );
}

export default EditUser;