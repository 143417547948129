import React, {useEffect, useState, useContext} from 'react';
import './styles.css';
import SideBar from '../../components/AppComponents/SideBar';
import MainContent from '../../components/AppComponents/MainContent';
import auth from '../../services/auth';
import {SideBarContext} from '../../providers/SideBarStatus';
import base64 from 'base-64';
import { Container } from 'react-bootstrap';

function Home()
{

    const [atualUser, setAtualUser] = useState({
        name: String,
        user: String,
        email: String,
        tipo: String,
        id: String
    });

    const {selected, setSelected}: any = useContext(SideBarContext);

    useEffect(()=>{
        getUserLogado()
    },[]);

    const getUserLogado = ()=>
    {
        const userLogado:any = auth.getUserLogado();
        setAtualUser(userLogado);
    }

    const logout = ()=>
    {
        auth.logout();
    }

    const checkboxChange = (e: any)=>
    {
        if(selected.selected === false)
        {
            setSelected({
                selected: true
            })
        }else
        {
            setSelected({
                selected: false
            })
        }
    }

    return(
        <div className="fundo">
            <input type="checkbox" checked={selected.selected} onChange={checkboxChange} id="sidebar-toogle"></input>
            <SideBar>
                <li>
                    <a href="#" onClick={logout}>
                        <span className="fas fa-sign-out-alt"></span>
                        <span>Sair</span>
                    </a>
                </li>
            </SideBar>
            <MainContent title="Início" tipoAcesso={auth.getTipoAcesso()} permissionOne={true} permissionTwo={true} permissionThree={true}>
                <Container>
                    <h2>Bem Vindo, {auth.getName()}</h2>
                </Container>
            </MainContent>
        </div>
    )
}




export default Home;